import { Button, Card, Modal } from "react-bootstrap";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Progress } from "antd";
import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import CloseModalTab from "../../component/Nav/CloseModalTab";
import GiftDetailModal from "./GiftDetailModal";
import Swal from "sweetalert2";
import { useAuth } from "../../context/auth-context";

function MyAddressEditModal({
  show,
  setShow,
  addressText,
  setAddresstext,
  submitAddress,
  setTShirtSize,
  productGUID,
}) {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      style={{
        top: `${
          productGUID === "912B8553-7BA2-4EE2-8A4C-D7B85A3F83A5" ? "25%" : "35%"
        }`,
      }}
      // centered
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      backdropClassName="custom-backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup row>
          <Label for="exampleText" sm={2}>
            Address
          </Label>
          <Col sm={10}>
            <Input
              value={addressText}
              onChange={e => {
                setAddresstext(e.target.value);
              }}
              id="exampleText"
              name="text"
              type="textarea"
            />
          </Col>
        </FormGroup>
        {productGUID === "912B8553-7BA2-4EE2-8A4C-D7B85A3F83A5" ? (
          <FormGroup>
            <Label for="exampleSelect">T-shirts size</Label>
            <Input
              onChange={e => {
                setTShirtSize(e.target.value);
              }}
              type="select"
            >
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
            </Input>
          </FormGroup>
        ) : null}
        <div>
          <Button
            onClick={() => {
              submitAddress();
            }}
            color="primary"
          >
            Submit
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const GiftModel = ({
  show,
  setShow,
  totalNonRedeemPoints,
  allGiftDetail,
  redeemGift,
}) => {
  const [showVoucherDetailModel, setShowVoucherDetailModel] = useState(false);
  const [redeemDetails, setRedeemDetails] = useState();
  const [giftDetails, setGiftDetails] = useState();
  const [redeemGiftDetail, setRedeemGiftDetail] = useState();
  const [showDialog, setShowDialog] = useState();
  const [addressText, setAddresstext] = useState();
  const [points, setPoints] = useState();
  const [productGUID, setProductGUID] = useState("");
  const [tShirtSize, setTShirtSize] = useState("S");
  const { user } = useAuth();
  const GetUserData = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error);
      return;
    } else {
      setAddresstext(result.data?.data?.address);
      setShowDialog(true);
    }
  };

  const submitAddress = async () => {
    const [error, result] = await asyncWrap(
      axios.post("/editProfile", {
        userType: user?.profile?.roleName || user?.roleName,
        userId: user?.profile?.userId || user.userId,
        addressLine1: addressText,
        linkedInProfile:
          (user?.profile?.roleName || user?.roleName) === "Electrician"
            ? tShirtSize
            : "",
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
      history.replace("/home");
    } else if (result.data?.success) {
      redeemGift(points, productGUID);
      setShowDialog(false);
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };

  const getGiftDetail = async (type, pointTypeId) => {
    const [error, result] = await asyncWrap(
      axios({
        method: "get",
        url: `/redeemed/details?type=${type}&pointId=${pointTypeId}`,
      })
    );
    if (!result) {
      console.log(error);
      return;
    }
    setRedeemDetails(result.data);
  };

  const getRedeemGiftDetail = async () => {
    const [error, result] = await asyncWrap(axios.get("/redeemed/gift"));
    if (!result) {
      console.log(error);
      return;
    }
    setRedeemGiftDetail(result.data.data);
  };

  const optfilter = [
    { title: "All Gift", key: 1 },
    { title: "Redeemed Gift", key: 2 },
  ];
  const [active, setActive] = useState(1);

  useEffect(() => {
    getRedeemGiftDetail();
  }, []);

  return (
    <>
      <GiftDetailModal
        show={showVoucherDetailModel}
        setShow={setShowVoucherDetailModel}
        redeemDetails={redeemDetails}
        giftDetails={giftDetails}
      />
      <MyAddressEditModal
        addressText={addressText}
        setAddresstext={setAddresstext}
        submitAddress={submitAddress}
        show={showDialog}
        setShow={setShowDialog}
        setTShirtSize={setTShirtSize}
        productGUID={productGUID}
      />
      <Modal fullscreen={true} show={show}>
        <Modal.Body className="w-100 p-2 mt-3">
          <CloseModalTab onclick={() => setShow(false)} />

          <div className="horizontal-scroll mt-20">
            {optfilter.map(({ title, key }) => (
              <Badge
                key={key}
                onClick={() => setActive(key)}
                pill
                className={key === active ? "rank-badge badge" : "badge"}
                style={{
                  background:
                    key === active ? "#fad6a5 !important" : "white !important",
                }}
              >
                {title}
              </Badge>
            ))}
          </div>
          {active === 1 && (
            <div>
              <Row>
                {allGiftDetail &&
                  allGiftDetail.map((item, index) => (
                    <Col
                      key={index}
                      xs={20}
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <Card
                        style={{
                          backgroundColor: "#fff",
                          height: "100%",
                          marginBottom: "10px",
                          // boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
                          borderRadius: "0px",
                        }}
                        // onClick={() => {
                        //   setVoucherDetail(item);
                        //   setShowVoucherDetailModel(true);
                        // }}
                      >
                        {/* <div
                          style={{
                            borderRadius: "15px",
                            display: "flex",
                            marginTop: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              marginLeft: "20px",
                              marginTop: "15px",
                            }}
                          >
                            {item?.Brand}
                          </p>
                          <Card.Img
                            src={item?.ImageUrl}
                            style={{
                              width: "64px",
                              height: "64px",
                              backgroundColor: "#FFCA9F",
                              marginRight: "20px",
                            }}
                          />
                        </div> */}
                        <p
                          style={{
                            color: "#000",
                            marginLeft: "20px",
                            marginTop: "10px",
                          }}
                        >{`Earn ${item.points} points to Redeem this voucher`}</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Card.Text
                            style={{
                              marginLeft: "20px",
                              marginTop: "5px",
                              color: "#000",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            {item?.ProductServiceName}
                          </Card.Text>
                          {totalNonRedeemPoints >= item.points && (
                            <Button
                              variant="success"
                              style={{
                                marginRight: "20px",
                                borderRadius: "10px",
                              }}
                              onClick={() => {
                                setPoints(item.points);
                                setProductGUID(item.ProductGUID);
                                GetUserData();
                              }}
                            >
                              Redeem
                            </Button>
                          )}
                        </div>
                        <div
                          style={{ marginLeft: "20px", marginRight: "40px" }}
                        >
                          <Progress
                            trailColor="#ccc"
                            percent={(
                              (totalNonRedeemPoints / item?.points) *
                              100
                            ).toFixed(2)}
                          />
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </div>
          )}
          {active === 2 && (
            <div>
              <Row>
                {redeemGiftDetail &&
                  redeemGiftDetail.map((item, index) => (
                    <Col
                      key={index}
                      xs={20}
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <Card
                        style={{
                          backgroundColor: "#fff",
                          height: "100%",
                          marginBottom: "10px",
                          // boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
                          borderRadius: "0px",
                        }}
                        onClick={() => {
                          setGiftDetails(item);
                          getGiftDetail("Gift", item.RequestReedemPointId);
                          setShowVoucherDetailModel(true);
                        }}
                      >
                        {/* <div
                          style={{
                            borderRadius: "15px",
                            display: "flex",
                            marginTop: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "20px",
                              color: "#ccc",
                              marginLeft: "20px",
                              marginTop: "15px",
                            }}
                          >
                            {item?.ProductServiceName}
                          </p>
                          <Card.Img
                            src={item?.ImageUrl}
                            style={{
                              width: "64px",
                              height: "64px",
                              backgroundColor: "#FFCA9F",
                              marginRight: "20px",
                            }}
                          />
                        </div> */}
                        <p
                          style={{
                            color: "#000",
                            marginLeft: "20px",
                            marginTop: "10px",
                          }}
                        >{`Earn ${item?.Point} points to Redeem this voucher`}</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Card.Text
                            style={{
                              marginLeft: "20px",
                              marginTop: "5px",
                              color: "#000",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            {item?.ProductServiceName}
                          </Card.Text>
                          {/* {totalNonRedeemPoints >= item.points && (
                            <Button
                              variant="success"
                              style={{
                                marginRight: "20px",
                                borderRadius: "10px",
                              }}
                            >
                              Redeem
                            </Button>
                          )} */}
                        </div>
                        {/* <div
                          style={{ marginLeft: "20px", marginRight: "40px" }}
                        >
                          <Progress
                            percent={(
                              (totalNonRedeemPoints / item?.points) *
                              100
                            ).toFixed(2)}
                          />
                        </div> */}
                      </Card>
                    </Col>
                  ))}
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GiftModel;
