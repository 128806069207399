import { useEffect, useState, useCallback } from 'react';
import { List, Pagination, Skeleton, DatePicker } from 'antd';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import DetailPoints from './DetailPoints';
const PAGE_SIZE = 10;

const GetPaginationData = (url) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async (pageNo, urlParams) => {
      setLoading(true);
      try {
        const response = await axios({
          url,
          params: {
            pageNo,
            pageSize: PAGE_SIZE,
            schemetype: "Wire",
            ...urlParams,
          },
        });

        setData(response.data?.data);
        setTotal(response.data.total);
        setLoading(false);
      } catch (error) {
        console.error('Error => ', error);
        setLoading(false);
      }
    },
    [url]
  );

  const gotoPage = (pageNo, urlParams) => {
    setCurrentPage(pageNo);
    fetchData(pageNo, { ...urlParams });
  };

  useEffect(() => {
    //currentPage
    fetchData(1);
  }, [fetchData]);

  const pages = Array.from(
    { length: Math.ceil(total / PAGE_SIZE) },
    (v, i) => i + 1
  );

  return { data, gotoPage, currentPage, pages, total, loading, fetchData };
};

const WireLedger = () => {
  const [showDetailPoints, setShowDetailPoints] = useState(false);
  const [dataDetailPoint, setDataDetailPoint] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const {
    data,
    gotoPage,
    currentPage,
    loading,
    total,
    pages,
  } = GetPaginationData('switch_wire_ledger');
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
    gotoPage(event.target.value);
  };

  const renderOptions = () => {
    const options = [];

    for (let i = 1; i <= pages.length; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return options;
  };

  return (
    <>
      <DetailPoints
        show={showDetailPoints}
        setShow={setShowDetailPoints}
        data={dataDetailPoint}
      />
      <Skeleton active loading={loading}>
        <div bodystyle={{ paddingTop: '15px' }}>
          <List
            style={{ marginTop: '10px' }}
            dataSource={data}
            renderItem={(item, key) => (
              <List.Item
                // onClick={() => {
                //   setDataDetailPoint(item);
                //   setShowDetailPoints(true);
                // }}
                className='d-flex my-3 flex-row justify-content-between shadow rounded-3 p-3'
                key={key}
              >
                <div className='w-75'>
                  <div
                    style={{ color: '#434343', fontSize: '16px' }}
                    className='fw-bold'
                  >
                    {item?.SchemeName}
                  </div>
                  <div style={{ color: '#7B7B7B', fontSize: '15px' }}>
                    {moment(item?.PointEarnDate).format('DD-MM-YYYY, LT')}
                  </div>
                </div>
                <div
                  style={{ color: '#3A69DD', fontSize: '20px' }}
                  className='fw-bold'
                >
                  🏆 {item?.PointsEarned}
                </div>
              </List.Item>
            )}
          />
          <nav
            className='mt-5'
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <select
              className='simple-select'
              value={selectedOption}
              onChange={handleOptionChange}
            >
              {renderOptions()}
            </select>
            <Pagination
              showQuickJumper={true}
              current={currentPage}
              defaultPageSize={10}
              pageSize={PAGE_SIZE}
              total={total}
              onChange={page => gotoPage(page)}
            />
          </nav>
        </div>
      </Skeleton>
    </>
  );
};

export default WireLedger;
