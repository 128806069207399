import React from 'react'

function DostiFive() {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe
        src='https://drive.google.com/file/d/1OLsCqcrGs59cM81HUC_zm9qjBRbQgiBO/preview'
        style={{ height: '100%', width: '100%', border: 'none' }}
        title='PDF Viewer'
      />
    </div>
  )
}

export default DostiFive