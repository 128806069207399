import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiDownload } from "react-icons/hi";
import axios from "axios";
import { asyncWrap } from "../../utils/utils";

const MonsoonBananza24 = () => {
  const [coilCoins, setCoilCoins] = useState(0);

  useEffect(() => {
    getTotalCoils();
  }, []);

  const getTotalCoils = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (!result) {
      console.log(error);
      return;
    }
    setCoilCoins(parseInt(result.data?.data?.CoilScanFrom1stJuly2024To30thSept2024));
  };

  return (
    <>
      <Modal fullscreen={true} show={true}>
        <Modal.Body>
          <div className="d-flex text-center flex-column">
            <table className="custom-table">
              <thead>
                <tr style={{ fontSize: "16px" }}>
                  <th style={{ border: "1px solid #000", textAlign: "start" }}>
                    Slab
                  </th>
                  <th style={{ border: "1px solid #000", textAlign: "start" }}>
                    Target Slab
                  </th>
                  <th style={{ border: "1px solid #000", textAlign: "start" }}>
                  How many Coils Scanned (1st July to 30th September)
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "16px" }}>
                <tr>
                  <td>L1</td>
                  <td>31</td>
                  <td>{ (coilCoins >= 31 && coilCoins < 101) ? coilCoins : "" }</td>
                </tr>

                <tr>
                  <td>L2</td>
                  <td>101</td>
                  <td>{(coilCoins >= 101 && coilCoins < 201) ? coilCoins : ""}</td>
                </tr>
                <tr>
                  <td>L3</td>
                  <td>201</td>
                  <td>{(coilCoins >= 201 && coilCoins < 301) ? coilCoins : ""}</td>
                </tr>
                <tr>
                  <td>L4</td>
                  <td>301</td>
                  <td>{(coilCoins >= 301 && coilCoins < 501) ? coilCoins : ""}</td>
                </tr>
                <tr>
                  <td>L5</td>
                  <td>501</td>
                  <td>{(coilCoins >= 501 && coilCoins < 751) ? coilCoins : ""}</td>
                </tr>
                <tr>
                  <td>L6</td>
                  <td>751</td>
                  <td>{(coilCoins >= 751 && coilCoins < 1251) ? coilCoins : ""}</td>
                </tr>
                <tr>
                  <td>L7</td>
                  <td>1251</td>
                  <td>{(coilCoins >= 1251 && coilCoins < 2001) ? coilCoins : ""}</td>
                </tr>
                <tr>
                  <td>L8</td>
                  <td>2001</td>
                  <td>{(coilCoins >= 2001 && coilCoins < 3001) ? coilCoins : ""}</td>
                </tr>
                <tr>
                  <td>L9</td>
                  <td>3001</td>
                  <td>{(coilCoins >= 3001) ? coilCoins : ""}</td>
                </tr>
              </tbody>
            </table>

            <div className="my-2 home-card-top-border">
              <div
                onClick={() => {
                  window.open(
                    "https://drive.google.com/file/d/1t-AMI636aar0lOqzBj2lkgSOfPwqmoy0/preview",
                    "_self"
                  );
                }}
                className="fs-5 mt-3 p-1 home-page-card"
              >
                View
                <HiDownload style={{marginLeft:"10px"}} />
              </div>
            </div>

            {/* <div className="my-2 home-card-top-border">
              <div
                onClick={() => {
                  window.open(
                    "https://drive.google.com/file/d/1JIQTAU-xB12ZN2RW3JF_001nlaqgi5CO/preview",
                    "_self"
                  );
                }}
                className="fs-5 mt-3 p-1 home-page-card"
              >
                Terms and Conditions
                <HiDownload style={{ marginLeft: "10px" }} />
              </div>
            </div> */}
            {/* <h3 className="mt-4">Your total coils scanned : { coilCoins}</h3> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MonsoonBananza24;
