import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiDownload } from "react-icons/hi";
import axios from "axios";
import moment from "moment";
import { asyncWrap } from "../../utils/utils";

const YearEndBonanza = () => {
  const [coilCoins, setCoilCoins] = useState("0");

  useEffect(() => {
    getTotalCoils();
  }, []);

  const getTotalCoils = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (!result) {
      console.log(error);
      return;
    }
    setCoilCoins(result.data?.data.CoilScanedFrom11thOctober2023TillDate);
  };

  return (
    <>
      <Modal fullscreen={true} show={true}>
        <Modal.Body>
          <div className="d-flex text-center flex-column">
            <div
              style={{
                border: "1px solid #000",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              Timeline - 1th Jan 24 to {moment().format("Do MMM YY")}
            </div>
            <h3 className="mt-4">Your total coils scanned : { coilCoins}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default YearEndBonanza;
