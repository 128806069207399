import { Card, Modal } from "react-bootstrap";
import { Image, Steps } from "antd";
import CloseModalTab from "../../component/Nav/CloseModalTab";

const { Step } = Steps;
const GiftDetailModal = ({ show, setShow, redeemDetails, giftDetails }) => {
  return (
    <Modal fullscreen={true} show={show}>
      <Modal.Body className="w-100 p-2 mt-3">
        <CloseModalTab />
        <Card
          style={{
            backgroundColor: "#fff",
            marginBottom: "10px",
            // boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
            borderRadius: "0px",
          }}
        >
          {/* <div
            style={{
              borderRadius: "15px",
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                color: "#fff",
                marginLeft: "20px",
                marginTop: "15px",
              }}
            >
              {giftDetails?.ProductServiceName}
            </p>
            <Card.Img
              src={giftDetails?.ImageUrl}
              style={{
                width: "64px",
                height: "64px",
                backgroundColor: "#FFCA9F",
                marginRight: "20px",
              }}
            />
          </div> */}
          <p
            style={{
              color: "#000",
              marginLeft: "20px",
              marginTop: "10px",
            }}
          >{`Earn ${giftDetails?.Point} points to Redeem this voucher`}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Card.Text
              style={{
                marginLeft: "20px",
                marginTop: "5px",
                color: "#000",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              {giftDetails?.ProductServiceName}
            </Card.Text>
          </div>
        </Card>
        <Image
          rootClassName="d-flex flex-columns justify-content-center mt-3"
          width="100%"
          src={giftDetails?.ImageUrl}
          height={150}
        />
        {giftDetails?.IsUserReceived !== 1 && (
          <div className="mt-4">
            <h4>Pin : {redeemDetails?.pin}</h4>
            <h6>
              Share this pin with the salesperson while receiving the gift.
            </h6>
          </div>
        )}
        <Steps progressDot className="mt-4" direction="vertical" current={1}>
          {redeemDetails &&
            redeemDetails?.data?.map((item, index) => (
              <Step
                key={index}
                title={item?.TextValue}
                description={
                  <img
                    style={{ width: "100px", height: "100%" }}
                    src={item?.Images}
                  />
                }
              />
            ))}
        </Steps>
      </Modal.Body>
    </Modal>
  );
};

export default GiftDetailModal;
