import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router";

const MapComponent = () => {
  const history = useHistory();
  // Replace 'YOUR_API_KEY' with your actual Google Maps API key
  const apiKey = "AIzaSyCDOlaoruhiTk62Hki1lKaqdTAliBAQT9Y";

  // State variables to track the selected location
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 17.7128,
    lng: 79.006,
  });
  const [addressDetails, setAddressDetails] = useState(null);
  const [mapZoom, setMapZoom] = useState(4);
  // Event handler when the map is clicked
  const onMapClick = async e => {
    setSelectedLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    // Get address details when the map is clicked
    getAddressDetails(e.latLng.lat(), e.latLng.lng());
  };

  // Function to get address details using Geocoding API
  const getAddressDetails = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch address details");
      }

      const data = await response.json();

      if (data.status === "OK") {
        const address = data.results[0].address_components;
        setAddressDetails({
          formattedAddress: data.results[0].formatted_address,
          city: address.find(component => component.types.includes("locality"))
            ?.long_name,
          state: address.find(component =>
            component.types.includes("administrative_area_level_1")
          )?.long_name,
          district: address.find(component =>
            component.types.includes("administrative_area_level_3")
          )?.long_name,
          country: address.find(component =>
            component.types.includes("country")
          )?.long_name,
          pincode: address.find(component =>
            component.types.includes("postal_code")
          )?.long_name,
        });
      } else {
        throw new Error("Failed to fetch address details");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    if (!selectedLocation || !addressDetails) {
      Swal.fire("Please pick a location on the map first.");
      return;
    }

    Swal.fire({
      title: "Address Details",
      html: `
        <p><strong>Formatted Address:</strong> ${addressDetails.formattedAddress}</p>
        <p><strong>City:</strong> ${addressDetails.city}</p>
        <p><strong>State:</strong> ${addressDetails.state}</p>
        <p><strong>District:</strong> ${addressDetails.district}</p>
        <p><strong>Country:</strong> ${addressDetails.country}</p>
        <p><strong>Pincode:</strong> ${addressDetails.pincode}</p>
      `,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
    }).then(async result => {
      if (result.isConfirmed) {
        const [error, result] = await asyncWrap(
          axios.post("/editProfile", {
            addresslat: selectedLocation.lat,
            addresslong: selectedLocation.lng,
            pinCode: addressDetails.pincode,
            addressLine2: addressDetails.formattedAddress,
            city: addressDetails.city,
          })
        );

        if (!result) {
          Swal.fire(error.response.data.message);
          return;
        }
        Swal.fire(result.data.message).then(() => {
          history.push("/home");
        });
      }
    });
  };
  const customMapOptions = {
    disableDefaultUI: true, // Disables all default UI controls
    zoomControl: true, // Enable zoom control
  };

  const [address, setAddress] = useState(""); // State to track the address input

  // Function to handle address selection from autocomplete
  const handleSelect = async selectedAddress => {
    setAddress(selectedAddress);

    // Use the selected address to fetch its details (e.g., lat/lng)
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      setSelectedLocation({
        lat: latLng.lat,
        lng: latLng.lng,
      });
      setMapZoom(17);
      // Fetch additional address details here if needed
    } catch (error) {
      console.error("Error fetching address details:", error);
    }
  };

  return (
    <div className="p-2">
      <h3>Please click on the map to pick a location</h3>
      <LoadScript googleMapsApiKey={apiKey} libraries={["places"]}>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div style={{ position: "relative", zIndex: "999" }}>
              <input
                {...getInputProps({
                  placeholder: "Search Places...",
                  className: "form-control",
                })}
              />
              <div
                style={{
                  position: "absolute",
                  borderRadius: "10px",
                  background: "white",
                  margin: "10px",
                  padding: "10px",
                  width: "95%",
                }}
                className="autocomplete-dropdown-container"
              >
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // Add your own styling here if needed

                  return (
                    <div
                      style={{
                        padding: "5px 5px",
                        borderBottom: "0.1px solid gray",
                      }}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <GoogleMap
          mapContainerStyle={{
            height: "500px",
            width: "100%",
            marginTop: "20px",
          }}
          zoom={mapZoom}
          options={customMapOptions}
          center={selectedLocation}
          onClick={onMapClick}
        >
          {selectedLocation && (
            <Marker
              position={{
                lat: selectedLocation.lat,
                lng: selectedLocation.lng,
              }}
            />
          )}
          {/* Add any additional components, markers, etc. here */}
        </GoogleMap>
      </LoadScript>
      <div className="d-flex flex-column justify-content-center mt-3">
        <button
          onClick={e => {
            e.preventDefault();
            handleSubmit();
          }}
          style={{ background: "#3A69DD", color: "white" }}
          className="fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 "
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default MapComponent;
