import { Button, Card, Modal } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import { Progress } from "antd";
import VoucherDetailModal from "./VoucherDetailModal";
import { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import axios from "axios";
import CloseModalTab from "../../component/Nav/CloseModalTab";
import { asyncWrap } from "../../utils/utils";

const VoucherModal = ({
  show,
  setShow,
  voucherData,
  redeemVoucher,
  totalRedeemPoints,
}) => {
  const [showVoucherDetailModel, setShowVoucherDetailModel] = useState(false);
  const [redeemedCoupon, setRedeemedCoupon] = useState();
  const [redeemDetails, setRedeemDetails] = useState();
  const [voucherDetails, setVoucherDetails] = useState();

  const getVoucherDetail = async (type, pointTypeId) => {
    const [error, result] = await asyncWrap(
      axios({
        method: "get",
        url: `/redeemed/details?type=${type}&pointId=${pointTypeId}`,
      })
    );
    if (!result) {
      console.log(error);
      return;
    }
    setRedeemDetails(result.data);
  };

  const getRedeemVoucherDetail = async () => {
    const [error, result] = await asyncWrap(axios.get("/redeemed/coupon"));
    if (!result) {
      console.log(error);
      return;
    }
    setRedeemedCoupon(result.data.data);
  };

  const optfilter = [
    { title: "All Voucher", key: 1 },
    { title: "Redeemed Voucher", key: 2 },
  ];
  const [active, setActive] = useState(1);

  useEffect(() => {
    getRedeemVoucherDetail();
  }, []);

  return (
    <>
      <VoucherDetailModal
        show={showVoucherDetailModel}
        setShow={setShowVoucherDetailModel}
        voucherDetails={voucherDetails}
        redeemDetails={redeemDetails}
      />
      <Modal fullscreen={true} show={show}>
        <Modal.Body className="w-100 p-2 mt-3">
          <div className="horizontal-scroll mt-20">
            {optfilter.map(({ title, key }) => (
              <Badge
                key={key}
                onClick={() => setActive(key)}
                pill
                className={key === active ? "rank-badge badge" : "badge"}
                style={{
                  background:
                    key === active ? "#fad6a5 !important" : "white !important",
                }}
              >
                {title}
              </Badge>
            ))}
          </div>
          {active === 1 && (
            <div>
              <Row>
                {voucherData &&
                  voucherData.map((item, index) => (
                    <Col
                      key={index}
                      xs={20}
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <Card
                        style={{
                          backgroundColor: "#fff",
                          height: "100%",
                          marginBottom:"10px",
                          // boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
                          borderRadius: "0px",
                        }}
                      >
                        {/* <div
                          style={{
                            borderRadius: "15px",
                            display: "flex",
                            marginTop: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              marginLeft: "20px",
                              marginTop: "15px",
                            }}
                          >
                            {item?.Brand}
                          </p>
                          <Card.Img
                            src={item?.ImageUrl}
                            style={{
                              width: "64px",
                              height: "64px",
                              backgroundColor: "#FFCA9F",
                              marginRight: "20px",
                            }}
                          />
                        </div> */}
                        <p
                          style={{
                            color: "#000",
                            marginLeft: "20px",
                            marginTop: "10px",
                          }}
                        >{`Earn ${item.points} points to Redeem this voucher`}</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Card.Text
                            style={{
                              marginLeft: "20px",
                              marginTop: "5px",
                              color: "#000",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            {item?.ProductServiceName}
                          </Card.Text>
                          {totalRedeemPoints >= item.points && (
                            <Button
                              onClick={() => {
                                redeemVoucher(item.points, item.ProductGUID);
                              }}
                              variant="success"
                              style={{
                                marginRight: "20px",
                                borderRadius: "10px",
                              }}
                            >
                              Redeem
                            </Button>
                          )}
                        </div>
                        <div
                          style={{ marginLeft: "20px", marginRight: "40px" }}
                        >
                          <Progress
                            trailColor="#ccc"
                            percent={(
                              (totalRedeemPoints / item?.points) *
                              100
                            ).toFixed(2)}
                          />
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </div>
          )}
          {active === 2 && (
            <div>
              <Row>
                {redeemedCoupon &&
                  redeemedCoupon.map((item, index) => (
                    <Col
                      key={index}
                      xs={20}
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <Card
                        style={{
                          backgroundColor: "#fff",
                          height: "100%",
                          marginBottom:"10px",
                          // boxShadow: "0px 4px 20px rgba(254, 169, 101, 0.5)",
                          borderRadius: "0px",
                        }}
                        onClick={() => {
                          setVoucherDetails(item);
                          getVoucherDetail("Coupon", item.RequestReedemPointId);
                          setShowVoucherDetailModel(true);
                        }}
                      >
                        {/* <div
                          style={{
                            borderRadius: "15px",
                            display: "flex",
                            marginTop: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              marginLeft: "20px",
                              marginTop: "15px",
                            }}
                          >
                            {item?.ProductServiceName}
                          </p>
                          <Card.Img
                            src={item?.ImageUrl}
                            style={{
                              width: "64px",
                              height: "64px",
                              backgroundColor: "#FFCA9F",
                              marginRight: "20px",
                            }}
                          />
                        </div> */}
                        <p
                          style={{
                            color: "#000",
                            marginLeft: "20px",
                            marginTop: "10px",
                          }}
                        >{`Earn ${item?.Point} points to Redeem this voucher`}</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Card.Text
                            style={{
                              marginLeft: "20px",
                              marginTop: "5px",
                              color: "#000",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            {item?.ProductServiceName}
                          </Card.Text>
                          {/* {totalRedeemPoints >= item.points && (
                            <Button
                              onClick={() => {
                                redeemVoucher(item.points, item.ProductGUID);
                              }}
                              variant="success"
                              style={{
                                marginRight: "20px",
                                borderRadius: "10px",
                              }}
                            >
                              Redeem
                            </Button>
                          )} */}
                        </div>
                        {/* <div
                          style={{ marginLeft: "20px", marginRight: "40px" }}
                        >
                          <Progress
                            percent={(
                              (totalRedeemPoints / item?.points) *
                              100
                            ).toFixed(2)}
                          />
                        </div> */}
                      </Card>
                    </Col>
                  ))}
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VoucherModal;
