import React from 'react';

function UdaanFive() {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe
        src='https://drive.google.com/file/d/15wyv6N3SOkQbpPtdrzImxx52HHat3UP0/preview'
        style={{ height: '100%', width: '100%', border: 'none' }}
        title='PDF Viewer'
      />
    </div>
  );
}

export default UdaanFive;
