import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Rank from "./pages/Rank";
import ProfilePage from "./component/ProfilePage/ProfilePage";
import Survey from "./pages/Survey";
import Settings from "./pages/Settings";
import Intro from "./pages/Intro";
import Login from "./pages/Login";
import Stickers from "./pages/Stickers";
import PointsInformation from "./pages/PointsInformation";
import Group from "./pages/Group";
import LoadingScreen from "./pages/LoadingScreen";
import RedeemPage from "./pages/RedeemPage";
import { setAxiosDefault, setToken } from "./axiosDefaults";
import { useAuth } from "./context/auth-context";
import ScanCode from "./pages/ScanCode";
import AddEndUser from "./pages/AddEndUser";
import AddRetailor from "./pages/AddRetailor";
import Contest from "./pages/Contest";
import { createContext, useEffect, useState } from "react";
import Challenges from "./pages/Challenges";
import GamePointsInformation from "./pages/GamePoint";
import { WebAppConfig } from "./context/WebAppConfig";
import { asyncWrap } from "./utils/utils";
import axios from "axios";
import GetStarted from "./pages/GetStarted";
import CustomerChat from "./pages/CustomerChat";
import KycVerify from "./pages/KycVerify";
import Home2 from "./pages/Home2";
import Brocher from "./component/Home/Brocher";
import Schema from "./component/Home/Schema";
import PriceList from "./component/Home/PriceList";
import Swal from "sweetalert2";
import Analytics from "./component/Analytics/Analytics";
import Events from "./pages/Events";
// import Products from "./pages/Products";
import ScratchCardDetail from "./component/ScrachCardModal/ScratchCardDetail";
import TermsAndCondition from "./pages/TermsAndCondition";
import AboutUs from "./pages/AboutUs";
import i18next from "i18next";
// import Notification from "./pages/Notification";
import { useMokKeysStore } from "./store";
import JigyasaNEC from "./component/Home/JigyasaNEC";
import Spins from "./pages/Spin";
import MapComponent from "./component/MapComponent";
import Tracker from "@openreplay/tracker";
// import { MokPopup } from "@mok.one/sdk/react";
import FestivalBananza from "./component/Home/FestivalBananza";
import MonsoonBananza from "./component/Home/MonsoonBananza";
import Switches from "./pages/Switches";
import YearEndBonanza from "./component/Home/YearEndBonanza";
import ManagerDetails from "./component/Home/ManagerDetails";
import Udaan from "./component/Home/Udaan";
import Dosti from "./component/Home/Dosti";
import CurrentYearPoints from "./component/Home/CurrentYearPoints";
import PointsFormYearEnd from "./component/Home/PointsFormYearEnd";
import PointsFormDiwali from "./component/Home/PointsFormDiwali";
import SchemePointsRedeem from "./component/Home/SchemePointsRedeem";
import SummerBonanza from "./component/Home/SummerBonanza";
import MonsoonBananza24 from "./component/Home/MonsoonBananza24";
import UdaanFive from "./component/Home/UdaanFive";
import DostiFive from "./component/Home/DostiFive";
import DostiBonce from "./component/Home/DostiBonce";
import FestivalBananza24 from "./component/Home/FestivalBonanza24";
import ScratchCardDetailRR from "./component/Home/ScrachCardModalRR/ScratchCardDetailRR";

export const Language_Context = createContext();
const App = () => {
  const fetchMokKeys = useMokKeysStore(state => state.getUpdateKeys);
  const mokKeys = useMokKeysStore(state => state.mokKeys);
  const [language, setLangauge] = useState();
  const [webAppConfig, setWebAppConfig] = useState([]);
  setAxiosDefault();
  const { user, setUser } = useAuth();
  if (user?.token) setToken(user.token);

  // useEffect(() => {
  //   const tracker = new Tracker({
  //     projectKey: "JcT7JhfYyACJu0hxMhxO",
  //     ingestPoint: "https://telemetry.unomok.com/ingest",
  //     network: {
  //       ignoreHeaders:false,
  //       capturePayload: true,
  //     },
  //   });
  //   tracker.start();
  //   tracker.setUserID(user?.profile?.userId || user?.userId);
  // }, []);

  // const [loading, setLoading] = useState(true);
  // const [splaceScreen, setSplaceScreen] = useState();
  // const splashscreeenshow = true;
  // const getCmsSplaceScreen = async () => {
  //   const [error, result] = await asyncWrap(
  //     axios.get("/cmsValues/?cmsType=SplashScreen")
  //   );
  //   if (!result) {
  //     console.log(error);
  //     return;
  //   }
  //   setSplaceScreen(result.data.data.SplashScreen.SplashScreen);
  // };
  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3000);
  // }, []);
  // useEffect(() => {
  //   getCmsSplaceScreen();
  // });

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(
      axios.get("/cmsValues/?cmsType=WebAppConfig")
    );
    if (!result) {
      console.log(error);
      return;
    }
    if (result.data.data.WebAppConfig) {
      setWebAppConfig(JSON.parse(result.data.data?.WebAppConfig?.Descriptions));
    }
  };

  const Logout = () => {
    localStorage.clear();
    setToken(null);
    window.location.reload();
  };

  const getUserProfileData = async () => {
    if (user) {
      const [error, result] = await asyncWrap(axios.get("/userDetails"));
      if (error?.response?.status === 401) {
        Swal.fire("Please Logout and login again");
        return;
      }
      if (!result) {
        console.log(error);
        return;
      } else {
        
        const user_Details = result.data.data;

        if ((user_Details.roleName === "Retailer" && !user_Details.IsPanVerified) || (user_Details.roleName === "Retailer" && !user_Details.PancardNumber)) {
          const { value: PancardValue } = await Swal.fire({
            title: "Enter your Pancard",
            input: "text",
            inputLabel: "Your Pan Card",
            showCancelButton: false,
            inputValue: user_Details?.PancardNumber,
            allowOutsideClick: false,
            allowEscapeKey: false,
            inputValidator: value => {
              if (!value) {
                return "You need to write something!";
              }
              let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
              if (!pancardpattern.test(value)) {
                return "Please enter valid pan number";
              }
            },
          });

          if (PancardValue) {
            let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
            if (!pancardpattern.test(PancardValue)) {
              Swal.fire("Please enter valid pan number");
              return;
            }

            const [panError, panResult] = await asyncWrap(
              axios.post("/verifyKyc/verify-pan", {
                panNumber: PancardValue,
              })
            );

            if (!panResult) {
              Swal.fire(panError.response.data.data.message).then(() => {
                window.location.reload();
              });
              return;
            }
            if (!panResult.data.data.valid) {
              Swal.fire(panError.response.data.data.message).then(() => {
                window.location.reload();
              });
              return;
            } else {
              const [error, result] = await asyncWrap(
                axios.post(
                  "/editProfile",
                  user_Details?.PancardNumber !== PancardValue
                    ? {
                        userType: user?.profile?.roleName || user.roleName,
                        kycTypeId: "Pan Card",
                        userId: user?.profile?.userId || user.userId,
                        panNumber: PancardValue,
                        kycNumber: PancardValue,
                        ispanverify: 1,
                      }
                    : {
                        userType: user?.profile?.roleName || user.roleName,
                        userId: user?.profile?.userId || user.userId,
                        ispanverify: 1,
                      }
                )
              );
              if (!result) {
                Swal.fire(error.response.data.data.message);
                history.replace("/home");
              } else if (result.data?.success) {
                Swal.fire(result.data?.message);
                history.replace("/home");
              } else {
                Swal.fire(result?.data?.message).then(() => {
                  window.location.reload();
                });
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (window.location.search) {
      let token = window.location.search.split("=")[1];
      setToken(token);
      getUserProfile(token);
    }
    getCmsValues();
    if (user) {
      fetchMokKeys();
      getUserProfileData();
    }
    //eslint-disable-next-line
  }, [user]);

  const getUserProfile = async token => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error);
      return;
    } else {
      let data = result.data.data;
      data.token = token;
      setUser(data);
      setAxiosDefault();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("key_language")) {
      i18next.changeLanguage(localStorage.getItem("key_language"));
    }
  }, []);

  return (
    <WebAppConfig.Provider value={webAppConfig}>
      <Language_Context.Provider value={[language, setLangauge]}>
        <div className="App">
          {user ? (
            <BrowserRouter basename="/app">
              <div style={{ marginTop: 10, marginBottom: 50 }}>
                {/* <Navbar /> */}
                {/* {mokKeys && (
                  <MokPopup
                    readKey={mokKeys?.readKey}
                    writeKey={mokKeys?.writeKey}
                    id={user?.profile?.userId || user.userId}
                  />
                )} */}
                <Switch>
                  <Route path="/analytics" component={Analytics} />
                  <Route path="/home" component={Home} />
                  <Route path="/home2" component={Home2} />
                  <Route path="/rank" component={Rank} />
                  <Route path="/challenge" component={Challenges} />
                  <Route
                    path="/group"
                    component={() => {
                      window.location.href = "https://kabelstar.com/";
                      return null;
                    }}
                  />
                  <Route path="/profile" component={ProfilePage} />
                  <Route path="/edit-profile/:userId" component={AddEndUser} />
                  <Route
                    path="/edit-retailer/:userId"
                    component={AddRetailor}
                  />
                  <Route path="/survey/:id" component={Survey} />
                  <Route path="/settings" component={Settings} />
                  <Route path="/spin" component={Spins} />
                  <Route path="/intro" component={Intro} />
                  <Route path="/stickers" component={Stickers} />
                  <Route path="/points-info" component={PointsInformation} />
                  <Route path="/game-point" component={GamePointsInformation} />
                  <Route path="/login" component={Login} />
                  <Route path="/loading" component={LoadingScreen} />
                  <Route path="/redeem" component={RedeemPage} />
                  <Route path="/scan" component={ScanCode} />
                  <Route path="/games" component={Contest} />
                  <Route path="/customer-chat" component={CustomerChat} />
                  <Route path="/kycverify" component={KycVerify} />
                  <Route path="/brochure" component={Brocher} />
                  <Route path="/pricelist" component={PriceList} />
                  {/* <Route path="/schemes" component={Schema} /> */}
                  <Route path="/scratchcard" component={ScratchCardDetail} />
                  <Route path="/events" component={Events} />
                  <Route path="/festival-bananza" component={FestivalBananza} />
                  <Route path="/monsoonbonanza" component={MonsoonBananza} />
                  <Route path="/monsoonbonanza24" component={MonsoonBananza24} />
                  <Route path="/udaan-five" component={UdaanFive} />
                  <Route path="/dosti-five" component={DostiFive} />
                  <Route path="/dosti-bonus" component={DostiBonce} />
                  <Route path="/year-end-bonanza" component={YearEndBonanza} />
                  <Route path="/switches" component={Switches} />
                  <Route path="/terms" component={TermsAndCondition} />
                  <Route path="/aboutus" component={AboutUs} />
                  {/* <Route path="/notificationspage" component={Notification} /> */}
                  <Route path="/google-map" component={MapComponent} />
                  <Route path="/logout" component={Logout} />
                  <Route path="/jigyasanec" component={JigyasaNEC} />
                  <Route path="/manager_details" component={ManagerDetails} />
                  <Route path="/udaan" component={Udaan} />
                  <Route path="/dosti" component={Dosti} />
                  <Route path="/current_year_points" component={CurrentYearPoints} />
                  <Route path="/points_from_year_end" component={PointsFormYearEnd} />
                  <Route path="/points_from_diwali" component={PointsFormDiwali} />
                  <Route path="/schemes_points_redeem" component={SchemePointsRedeem} />
                  <Route path="/summer_bonanza" component={SummerBonanza} />
                  <Route path="/festival-bonanza24" component={FestivalBananza24} />
                  <Route path="/rr-scratchcard" component={ScratchCardDetailRR} />
                  <Redirect to="/loading" />
                </Switch>
              </div>

              {/* <BottomTab /> */}
            </BrowserRouter>
          ) : (
            <BrowserRouter basename="/app">
              <Switch>
                <Route path="/add-user" component={AddEndUser} />
                <Route path="/add-retailer" component={AddRetailor} />
                <Route path="/login" component={Login} />
                <Route path="/get-started" component={GetStarted} />
                <Route path="/loading" component={LoadingScreen} />
                <Redirect to="/get-started" />
              </Switch>
            </BrowserRouter>
          )}
        </div>
      </Language_Context.Provider>
    </WebAppConfig.Provider>
  );
};

export default App;
