import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiDownload } from "react-icons/hi";
import axios from "axios";
import moment from "moment";
import { asyncWrap } from "../../utils/utils";

const CurrentYearPoints = () => {
  const [availableDosti, setAvailableDosti] = useState("0");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (!result) {
      console.log(error);
      return;
    }
    // console.log(result.data.data.PointEarnFromsScan3rdApril2023TillDate)
    if (result) {
      setAvailableDosti(result.data?.data?.PointEarnFromsScan2rdApril2024TillDate || "0");
      setLoading(false)
    }
    // setCurrentPoint(result.data?.data?.totalReedemPoint);
  };

  return (
    <>
      <Modal fullscreen={true} show={true}>
        {loading ? (
          <div className="quiz-loader-main">
            <div id="quiz-loader"></div>
          </div>
        ) : (
          <Modal.Body>
            <div className="d-flex text-center flex-column">
              <div
                style={{
                  border: "1px solid #000",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                  Current Year Points (2024-25)
              </div>
              <div className="d-flex flex-column my-4">
                {/* <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Udaan 4.0  : {"  "}</div>
                <div>&emsp;{udaanpoint}</div>
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Current Points : {"  "}</div>
                <div>&emsp;{currentPoint}</div>
              </div> */}
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Current Year Points : {"  "}
                  </div>
                  <div>&emsp;{availableDosti}</div>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default CurrentYearPoints;
