import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiDownload } from "react-icons/hi";
import axios from "axios";
import moment from "moment";
import { asyncWrap } from "../../utils/utils";
// import { Select } from "antd";
import SuccessModal from "../Redeem/SuccessModal";
import FailedModal from "../Redeem/FailedModal";
import Select from "react-select";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { t } from "i18next";

const SchemePointsRedeem = () => {
  const [availableDosti, setAvailableDosti] = useState("0");
  const [diwaliPoints, setDiwaliPoints] = useState("0");
  const [yearEndPoints, setYearEndPoints] = useState("0");
  const [udaanpoint, setUdaanPoints] = useState("0");
  const [otherPoints, setOtherPoints] = useState("0");
  const [bankDetails, setBankDetails] = useState();
  const [selectPoint, setSelectPoint] = useState();
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [points_value, setPointsValue] = useState();
  const [totalRedeemPoints, setTotalRedeemPoint] = useState();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState("");
  const [name, setName] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedPoints, setSelectedPoints] = useState("");
  const [bankDetailsVerify, setBankDetailsVerify] = useState(false)

  const history = useHistory();

  //eslint-disable-next-line
  const isvalidAccount = new RegExp("[0-9]{9,18}");

  //eslint-disable-next-line
  const isvalidIfsc = new RegExp("^[A-Z]{4}[0][A-Z0-9]{6}$");
  const isvalidPaytm = new RegExp(/^[6-9]\d{9}$/);
  const isvalidUpi = new RegExp("[a-zA-Z0-9.-_]{2,256}@[a-zA-Z]{2,64}");
  const isName = new RegExp(/^[a-zA-Z0-9\s]+$/);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {}, [options]);

  const fetchData = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (!result) {
      console.log(error);
      return;
    }
    // console.log(result.data.data.PointEarnFromsScan3rdApril2023TillDate)
    if (result) {
      setAvailableDosti(
        result.data?.data?.PointEarnFromsScan2rdApril2024TillDate || "0"
      );
      setDiwaliPoints(result.data?.data?.Points_From_Diwali_Scheme || "0");
      setYearEndPoints(result.data?.data?.Points_From_Year_End_Scheme || "0");
      setUdaanPoints(result.data?.data?.Udaan_4_0_Eligiblity || "0");
      setOtherPoints(result.data?.data?.Other_Points_to_Redeem || "0");
      setBankDetails(result.data?.data?.beneficiaryDetail);
      setAccount(result.data?.data?.beneficiaryDetail?.accountNumber);
      setIfsc(result.data?.data?.beneficiaryDetail?.ifscNumber);
      setName(result.data?.data?.beneficiaryDetail?.name);
      setBankName(result.data?.data?.beneficiaryDetail?.bankName);
      setBankDetailsVerify(result.data?.data?.IsBankVerify);
      GetTotalPoints();

      const [error2, result2] = await asyncWrap(
        axios({
          method: "get",
          url: `/redeemedPoints?pageNo=1&pageSize=50`,
        })
      );
      if (!result2) {
        console.log(error2);
        return;
      } else {
        const options = [
          {
            value: "otherPoints",
            label: `Other Points to Redeem - ${result.data?.data?.Other_Points_to_Redeem}`,
          },
          {
            value: "udaanpoint",
            label: `Udaan 4.0 Eligibility Redeem - ${result.data?.data?.Udaan_4_0_Eligiblity}`,
          },
          // {
          //   value: "availableDosti",
          //   label: `Current Year Points (2024-25) - ${result.data?.data?.PointEarnFromsScan2rdApril2024TillDate}`,
          // },
          {
            value: "yearEndPoints",
            label: `Points From Year End Schemes - ${result.data?.data?.Points_From_Year_End_Scheme}`,
          },
          {
            value: "diwaliPoints",
            label: `Points From Diwali Schemes - ${result.data?.data?.Points_From_Diwali_Scheme}`,
          },
          // Add other options as needed
        ];

        const filteredOptions = options.filter(option => {
          switch (option.value) {
            case "udaanpoint":
              return (
                parseInt(result.data?.data?.Udaan_4_0_Eligiblity || 0) !== 0
              );
            case "otherPoints":
              return (
                parseInt(result.data?.data?.Other_Points_to_Redeem || 0) !== 0
              );
            // case "availableDosti":
            //   return (
            //     parseInt(
            //       result.data?.data?.PointEarnFromsScan2rdApril2024TillDate || 0
            //     ) !== 0
            //   );
            case "yearEndPoints":
              return (
                parseInt(
                  result.data?.data?.Points_From_Year_End_Scheme || 0
                ) !== 0
              );
            case "diwaliPoints":
              return (
                parseInt(result.data?.data?.Points_From_Diwali_Scheme || 0) !==
                0
              );
            // Add cases for other options as needed
            default:
              return true;
          }
        });

        const filteredOptions2 = filteredOptions.filter(option => {
          const pointValue = parseInt(
            option.value === "availableDosti"
              ? result.data?.data?.PointEarnFromsScan2rdApril2024TillDate || 0
              : option.value === "yearEndPoints"
              ? result.data?.data?.Points_From_Year_End_Scheme || 0
              : option.value === "udaanpoint"
              ? result.data?.data?.Udaan_4_0_Eligiblity || 0
              : option.value === "otherPoints"
              ? result.data?.data?.Other_Points_to_Redeem || 0
              : option.value === "diwaliPoints"
              ? result.data?.data?.Points_From_Diwali_Scheme || 0
              : 0
          );
          return !result2.data?.data?.some(
            request => parseInt(request.Point) === pointValue && request.StatusText !== "Rejected"
          );
        });

        console.log(options);
        console.log(filteredOptions);
        console.log(filteredOptions2);
        setOptions(filteredOptions2);
        setLoading(false);
      }
    }
    // setCurrentPoint(result.data?.data?.totalReedemPoint);
  };

  const GetTotalPoints = async () => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      return;
    }
    setTotalRedeemPoint(result.data.data.totalPoints[0].point);

    // setTotalNonRedeemPoint(result.data.data.totalPoints[1].point);
  };

  const isValidPoint = point => {
    if (+point > 0 && point <= totalRedeemPoints && !isNaN(+point)) {
      return false;
    } else {
      setPointsValue(point);
      return true;
    }
  };

  const EditDetails = async () => {
    setLoading(true)
    // setBankDetailsVerify(true)
    if (bankDetailsVerify) {
      Swal.fire("You already verify your bank details!");
      setLoading(false)
      return;
    }
    if (!isvalidAccount.test(account)) {
      Swal.fire("Please Enter valid Account Number!");
      setLoading(false)
      return;
    }
    if (!isName.test(name)) {
      Swal.fire("Please Enter valid Account Holder Name!");
      setLoading(false)
      return;
    }
    if (!isName.test(bankName)) {
      Swal.fire("Please Enter valid Bank Name!");
      setLoading(false)
      return;
    }
    if (!isvalidIfsc.test(ifsc)) {
      Swal.fire("Please Enter valid Ifsc Code!");
      setLoading(false)
      return;
    }

    // const [error, result] = await asyncWrap(
    //   axios.post("verifyKyc/verify-bank", {
    //     bankAccount: account,
    //     ifsc: ifsc,
    //     name: name,
    //     phone: ""
    //   })
    // );
    // if (!result) {
    //   Swal.fire(error.response.data.data.message).then(() => {
    //     // setLoading(false)
    //     window.location.reload();
    //   });
    //   // history.replace("/home");
    // } else if (result.data?.success) {
      const [userError, userResult] = await asyncWrap(
        axios.post("/editProfile",  {
          beneficiaryDetail: {
            name: name,
            accountNumber: account,
            ifscNumber: ifsc,
            bankName: bankName
          },
          // isBankVerify: true
        })
      );
      if (!userResult) {
        Swal.fire(error.response.data.data.message).then(() => {
          window.location.reload();
        });
      } else if (userResult.data?.success) {
        Swal.fire(userResult.data.message).then(() => {
          // setLoading(false)
          window.location.reload();
        });
      } else {
        Swal.fire(userResult?.data?.message).then(() => {
          // setLoading(false)
          window.location.reload();
        });
      }
    // } else {
    //   Swal.fire(result?.data?.message).then(() => {
    //     window.location.reload();
    //     // setLoading(false)
    //   });
    // }

    // const data = {
    //   beneficiaryDetail: {
    //     name: name,
    //     accountNumber: account,
    //     ifscNumber: ifsc,
    //     bankName: bankName,
    //   },
    // };
    // const [error, result] = await asyncWrap(axios.post("/editProfile", data));

    // if (!result) {
    //   Swal.fire(error.response.data.message);
    //   return;
    // }
    // setLoading(false);
    // Swal.fire(result.data.message).then(() => {
    //   history.push("/home");
    // });
    // setShow(false);
  };

  const AddRedeem = async () => {
    
    if (!bankDetailsVerify) {
      Swal.fire({
        title: "Your Bank Account Details is Not Verify",
        html: `
          <div>Account No: ${account}</div>
          <div>IFSC: ${ifsc}</div>
          <div>Account Holder Name: ${name}</div>
          <div>Bank Name: ${bankName}</div>
        `,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Verify",
        cancelButtonText:"Edit",
        focusConfirm: false,
      }).then((result) => {
        if (result?.isConfirmed) {
          EditDetails()
        }
      });
      return;
    }

    var pointsvalue;
    if (selectedPoints === "udaanpoint") {
      pointsvalue = parseInt(udaanpoint);
    } else if (selectedPoints === "otherPoints") {
      pointsvalue = parseInt(otherPoints);
    } else if (selectedPoints === "availableDosti") {
      pointsvalue = parseInt(availableDosti);
    } else if (selectedPoints === "yearEndPoints") {
      pointsvalue = parseInt(yearEndPoints);
    } else if (selectedPoints === "diwaliPoints") {
      pointsvalue = parseInt(diwaliPoints);
    }
    // switch (selectedPoints) {
    //   case "udaanpoint":
    //     pointsvalue = parseInt(udaanpoint);
    //   case "otherPoints":
    //     pointsvalue = parseInt(otherPoints);
    //   case "availableDosti":
    //     pointsvalue = parseInt(availableDosti);
    //   case "yearEndPoints":
    //     pointsvalue = parseInt(yearEndPoints);
    //   case "diwaliPoints":
    //     pointsvalue = parseInt(diwaliPoints);
    // }
    console.log(pointsvalue);
    // const pointsvalue = parseInt(otherPoints);
    if (!isValidPoint(pointsvalue)) {
      //eslint-disable-next-line
      const [error, result] = await asyncWrap(
        axios.post("/addRedeem", {
          points: pointsvalue,
          type: "Bank",
        })
      );
      if (!result) {
        setShowFailedModal(true);
        // Swal.fire(error.response.data.message);
      }
      console.log(result.data.data);
      if (result.data.code === 1) {
        setShowSuccessModal(true);
      } else if (result.data.code === 3) {
        Swal.fire(
          "Your previous payment request is processing, please try again when its completed. Thank you~"
        );
      } else {
        Swal.fire(result.data?.message);
        // Swal.fire(result.data.message);
      }
    } else {
      Swal.fire("Please enter valid points to redeem!");
    }
  };

  const handleChange = selectedOption => {
    console.log(selectedOption.value);
    // Handle selection change
    setSelectedPoints(selectedOption.value);
  };

  return (
    <>
      <SuccessModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        point={points_value}
      />
      <FailedModal
        show={showFailedModal}
        setShow={setShowFailedModal}
        point={points_value}
      />
      <Modal fullscreen={true} show={true}>
        {loading ? (
          <div className="quiz-loader-main">
            <div id="quiz-loader"></div>
          </div>
        ) : (
          <Modal.Body>
            <div className="d-flex text-center flex-column">
              <div
                style={{
                  border: "1px solid #000",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Schemes Points Details
              </div>
              <div className="d-flex flex-column my-4">
                {/* <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Udaan 4.0  : {"  "}</div>
                <div>&emsp;{udaanpoint}</div>
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Current Points : {"  "}</div>
                <div>&emsp;{currentPoint}</div>
              </div> */}
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Udaan 4.0 Eligibility : {"  "}
                  </div>
                  <div>&emsp;{udaanpoint} {
                      // options.find(option => option.value === "otherPoints")
                      options.find(option => option.value === "udaanpoint") ? "" : "  ✅"
                    }</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Other Points to Redeem : {"  "}
                    </div>
                    {/* {
                      // options.find(option => option.value === "otherPoints")
                      options.includes("otherPoints") ? "yes" : "no"
                    } */}
                  <div>&emsp;{otherPoints} {
                      // options.find(option => option.value === "otherPoints")
                      options.find(option => option.value === "otherPoints") ? "" : "  ✅"
                    }</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Points From Year End Schemes : {"  "}
                    </div>
                    <div>&emsp;{yearEndPoints}
                    {
                      // options.find(option => option.value === "otherPoints")
                      options.find(option => option.value === "yearEndPoints") ? "" : "  ✅"
                    }
                    </div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Points From Diwali Schemes : {"  "}
                  </div>
                    <div>&emsp;{diwaliPoints}
                    {
                      // options.find(option => option.value === "otherPoints")
                      options.find(option => option.value === "diwaliPoints") ? "" : "  ✅"
                    }
                    
                    </div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Current Year Points (2024-25) : {"  "}
                  </div>
                  <div>&emsp;{availableDosti}</div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #000",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Bank Details
              </div>
              {/* <div className="d-flex flex-column my-4">
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Account Holder Name : {"  "}
                  </div>
                  <div>&emsp;{bankDetails?.name}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Account Number : {"  "}
                  </div>
                  <div>&emsp;{bankDetails?.accountNumber}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>Bank Name : {"  "}</div>
                  <div>&emsp;{bankDetails?.bankName}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>IFSC Number : {"  "}</div>
                  <div>&emsp;{bankDetails?.ifscNumber}</div>
                </div>
              </div> */}
              {/* {(udaanpoint !== "0" || otherPoints !== "0") && (
                <>
                  <div className="text-start">select redeem point value</div>
                  <select
                    placeholder="Please Select Redeem Points"
                    style={{
                      padding: "8px 10px",
                      border: "1px solid black",
                      borderRadius: "5px",
                      background: "white",
                    }}
                    value={selectPoint}
                    onChange={e => {
                      setSelectPoint(e.target.value);
                    }}
                  >
                    {udaanpoint !== "0" && (
                      <option value="udaanpoint">Udaan 4.0 Eligibility</option>
                    )}
                    {otherPoints !== "0" && (
                      <option value="otherPoints">
                        Other Points to Redeem
                      </option>
                    )}
                  </select>
                </>
              )} */}

              <Form className="w-100">
                <FormGroup floating className="p-0 mt-2">
                  <Input
                    style={{ border: "1px solid #B0B0B0", width: "100%" }}
                    required
                    id="account_number"
                    value={account}
                    disabled={bankDetailsVerify}
                    onChange={e => setAccount(e.target.value)}
                    placeholder="Enter Your Account Number"
                    type="number"
                  />
                  <Label htmlFor="account_number">{t("ac_number")}</Label>
                </FormGroup>
                <FormGroup floating className="p-0">
                  <Input
                    style={{ border: "1px solid #B0B0B0", width: "100%" }}
                    required
                    id="ifsc_number"
                    value={ifsc}
                    disabled={bankDetailsVerify}
                    onChange={e => setIfsc(e.target.value)}
                    placeholder="Enter Your IFSC Number"
                    type="text"
                  />
                  <Label htmlFor="ifsc_number">{t("ifsc_code")}</Label>
                </FormGroup>
                <FormGroup floating className="p-0">
                  <Input
                    style={{ border: "1px solid #B0B0B0", width: "100%" }}
                    required
                    id="account_name"
                    value={name}
                    disabled={bankDetailsVerify}
                    onChange={e => setName(e.target.value)}
                    placeholder="Enter Your Account Holder Name"
                    type="text"
                  />
                  <Label htmlFor="account_name">{t("name")}</Label>
                </FormGroup>
                <FormGroup floating className="p-0">
                  <Input
                    style={{ border: "1px solid #B0B0B0", width: "100%" }}
                    required
                    id="bank_name"
                    value={bankName}
                    disabled={bankDetailsVerify}
                    onChange={e => setBankName(e.target.value)}
                    placeholder="Enter your bank name"
                    type="text"
                  />
                  <Label htmlFor="bank_name">{t("bank_name")}</Label>
                </FormGroup>
              </Form>

              {!bankDetailsVerify && (<button
                onClick={e => {
                  e.preventDefault();
                  EditDetails();
                }}
                style={{ background: "#3A69DD", color: "white" }}
                className="fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 mt-2"
              >
                Edit Bank Details
              </button>)}
              {(otherPoints !== "0" ||
                udaanpoint !== "0" ||
                availableDosti !== "0" ||
                diwaliPoints !== "0" ||
                yearEndPoints !== "0") && (
                <div className="mt-4">
                  {options.length > 0 && (
                    <Select
                      options={options}
                      onChange={handleChange}
                      isSearchable={false}
                      value={options.find(
                        option => option.value === selectedPoints
                      )}
                      placeholder="Select Points to Redeem"
                    />
                  )}
                </div>
              )}
              {(otherPoints !== "0" ||
                udaanpoint !== "0" ||
                availableDosti !== "0" ||
                diwaliPoints !== "0" ||
                yearEndPoints !== "0") && (
                <button
                  onClick={e => {
                    e.preventDefault();
                    // AddRedeem();
                    }}
                  disabled
                  style={{ background: "#3A69DD", color: "white" }}
                  className="fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 mt-2"
                >
                  Redeem
                </button>
              )}
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default SchemePointsRedeem;
