import { Modal } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import EarnedPoints from "../component/Point-Details/EarnedPoints";
import RedeemPoint from "../component/Point-Details/RedeemPoint";
import { useTranslation } from "react-i18next";
import NonRedeemPoint from "../component/Point-Details/NonRedeemPoint";
import CurrentYearPoints from "../component/Point-Details/CurrentYearPoints";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { webAppConfigIsVisible } from "../utils/isVisible";
import { WebAppConfig } from "../context/WebAppConfig";
import { useAuth } from "../context/auth-context";
import SwitchLedger from "../component/Point-Details/SwitchLedger";
import WireLedger from "../component/Point-Details/WireLedger";

const PointsInformation = () => {
  // eslint-disable-next-line
  const [show, setShow] = useState(true);
  const webAppConfigCtx = useContext(WebAppConfig);
  const [totalPoint, setTotalPoint] = useState();
  const [active, setActive] = useState(1);
  // const history = useHistory();
  const { t } = useTranslation();

  const { user } = useAuth();
  const [currentYearPoints, setCurrentYearPoints] = useState(0);
  const [lastYearPoint, setLastYearPoints] = useState(0);
  const [previousdataPoints, setPreviousDataPoints] = useState(0);
  const [totalNonRedeemPoints, setTotalNonRedeemPoint] = useState();
  const [instagramID, setInstagramID] = useState("0");

  const GetPointInfo = async () => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      return;
    }
    setCurrentYearPoints(result.data.data?.currentYearPoints);
    setLastYearPoints(result.data.data?.lastYearPoints);
    setPreviousDataPoints(result.data.data?.previousDataPoints);
    setTotalPoint(result.data.data.totalPoints[0].point);
    setTotalNonRedeemPoint(result.data.data.totalPoints[1].point);
  };

  const getUserDetails = async () => {
    //eslint-disable-next-line
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error.response);
      return;
    }
    setInstagramID(result?.data?.data?.InstagramId);
  };

  useEffect(() => {
    GetPointInfo();
    getUserDetails();
  }, []);

  const optfilter = [
    { title: "Scan History", key: 1, webconfig: false },
    { title: t("redeem_points"), key: 2, webconfig: false },
    // { title: t("gift_point"), key: 3, webconfig: false },
    {
      title:
        (user?.profile?.roleName || user?.roleName) === "Retailer"
          ? "Udaan 4.0"
          : "Dosti 4.0",
      key: 4,
      webconfig: true,
      webconfig_name: "Current Year",
    },
    { title: "Switch History", key: 7, webconfig: false },
    { title: "MCB History", key: 8, webconfig: false },
    // {
    //   title:
    //     (user?.profile?.roleName || user?.roleName) === "Retailer"
    //       ? "Udaan 3.0"
    //       : "Dosti 3.0",
    //   key: 5,
    //   webconfig: true,
    //   webconfig_name: "Last Year",
    // },
    // {
    //   title:
    //     (user?.profile?.roleName || user?.roleName) === "Retailer"
    //       ? "Udaan 2.0"
    //       : "Dosti 2.0",
    //   key: 6,
    //   webconfig: true,
    //   webconfig_name: "Previous data",
    // },
  ];

  return (
    <Modal fullscreen={true} show={show}>
      {/* <Modal.Header> */}
      {/* <SecoundaryHeader
          text={t("points_information")}
          sideHide="false"
          onBack={() => {
            history.push("/home");
            setShow(false);
          }}
        /> */}
      {/* </Modal.Header> */}
      <Modal.Body style={{ marginTop: "30px" }}>
        {parseInt(instagramID) <= 0 && (
          <>
            {active === 3 ? (
              <div>
                <div
                  style={{
                    fontSize: "20px",
                    color: "#7B7B7B",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Gift Points
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center mt-1">
                  <img
                    style={{
                      marginRight: "10px",
                      width: "25px",
                      height: "25px",
                    }}
                    src="/assets/images/Redeem/trophy.png"
                    alt="trophyimage"
                  />
                  <div
                    style={{ color: "#3A69DD", fontSize: "25px" }}
                    className="fw-bold"
                  >
                    {totalNonRedeemPoints}
                  </div>
                  <img
                    style={{
                      marginLeft: "10px",
                      width: "25px",
                      height: "25px",
                    }}
                    src="/assets/images/Redeem/trophy.png"
                    alt="tropyimage"
                  />
                </div>
              </div>
            ) : active === 4 ? (
              <div>
                <div
                  style={{
                    fontSize: "20px",
                    color: "#7B7B7B",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Udaan 4.0
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center mt-1">
                  <img
                    style={{
                      marginRight: "10px",
                      width: "25px",
                      height: "25px",
                    }}
                    src="/assets/images/Redeem/trophy.png"
                    alt="trophyimage"
                  />
                  <div
                    style={{ color: "#3A69DD", fontSize: "25px" }}
                    className="fw-bold"
                  >
                    {currentYearPoints && currentYearPoints[0]?.EarnPoints}
                  </div>
                  <img
                    style={{
                      marginLeft: "10px",
                      width: "25px",
                      height: "25px",
                    }}
                    src="/assets/images/Redeem/trophy.png"
                    alt="tropyimage"
                  />
                </div>
              </div>) : (<></>)}
            {/* // ) : (user?.profile?.roleName || user?.roleName) === 
            //   "Electrician" ? (
            //   <div>
            //     <div
            //       style={{
            //         fontSize: "20px",
            //         color: "#7B7B7B",
            //         fontWeight: "bold",
            //         textAlign: "center",
            //       }}
            //     >
            //       Current Points Balance
            //     </div>
            //     <div className="d-flex flex-row align-items-center justify-content-center mt-1">
            //       <img
            //         style={{
            //           marginRight: "10px",
            //           width: "25px",
            //           height: "25px",
            //         }}
            //         src="/assets/images/Redeem/trophy.png"
            //         alt="trophyimage"
            //       />
            //       <div
            //         style={{ color: "#3A69DD", fontSize: "25px" }}
            //         className="fw-bold"
            //       >
            //         {totalPoint}
            //       </div>
            //       <img
            //         style={{
            //           marginLeft: "10px",
            //           width: "25px",
            //           height: "25px",
            //         }}
            //         src="/assets/images/Redeem/trophy.png"
            //         alt="tropyimage"
            //       />
            //     </div>
            //   </div>
            // ) : (
            //   <></>
            // )}*/}
          </>
        )}
        <div className="horizontal-scroll mt-2 d-flex flex-row gap-3">
          {optfilter.map((item, index) =>
            item.webconfig ? (
              webAppConfigIsVisible(item.webconfig_name, webAppConfigCtx) && (
                <div
                  key={index}
                  onClick={() => setActive(item.key)}
                  className={
                    item.key === active ? "pointInfo-active" : "pointInfo"
                  }
                >
                  {item.title}
                </div>
              )
            ) : (
              <div
                key={index}
                onClick={() => setActive(item.key)}
                className={
                  item.key === active ? "pointInfo-active" : "pointInfo"
                }
              >
                {item.title}
              </div>
            )
          )}
        </div>

        {active === 1 && <EarnedPoints />}

        {active === 2 && <RedeemPoint />}

        {active === 3 && <NonRedeemPoint />}

        {active === 4 && (
          <CurrentYearPoints
            title={t("current_year_points")}
            point={totalPoint}
          />
        )}

        {
          active === 7 && (
            <SwitchLedger />
          )
        }
        {
          active === 8 && (
            <WireLedger />
          )
        }

        {active === 5 && (
          <CurrentYearPoints
            title={t("last_year_points")}
            point={
              lastYearPoint && totalPoint - currentYearPoints[0]?.EarnPoints > 0
                ? totalPoint - currentYearPoints[0]?.EarnPoints
                : 0
            }
          />
        )}

        {active === 6 && (
          <CurrentYearPoints
            title={t("previous_data_points")}
            point={
              previousdataPoints &&
              totalPoint -
                currentYearPoints[0]?.EarnPoints -
                lastYearPoint[0]?.EarnPoints >
                0
                ? totalPoint -
                  currentYearPoints[0]?.EarnPoints -
                  lastYearPoint[0]?.EarnPoints
                : 0
            }
          />
        )}
        {/* <Tabs defaultActiveKey="earn" id="uncontrolled-tab" className="mb-3">
          <Tab eventKey="earn" title={t("earned_points")}>
            <EarnedPoints />
          </Tab>
          <Tab eventKey="redeem" title={t("redeem_points")}>
            <RedeemPoint />
          </Tab>
          <Tab eventKey="nonredeem" title={t("non_redeem_points")}>
            <NonRedeemPoint />
          </Tab>
        </Tabs> */}
      </Modal.Body>
    </Modal>
  );
};

export default PointsInformation;
