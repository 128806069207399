import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";

const VideoSlider = ({ data }) => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(null);

  const showSlide = index => {
    setCurrentIndex(index);
  };

  const handleTouchStart = event => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = event => {
    const currentX = event.touches[0].clientX;
    const diffX = startX - currentX;

    if (diffX > 0 && currentIndex < data.length - 1) {
      // Swipe left to show the next slide
      showSlide(currentIndex + 1);
    } else if (diffX < 0 && currentIndex > 0) {
      // Swipe right to show the previous slide
      showSlide(currentIndex - 1);
    }
  };

  const handleTransitionEnd = () => {
    carouselRef.current.style.transition = "";
  };

  const handleCarouselRef = element => {
    carouselRef.current = element;
  };

  useEffect(() => {
    // Automatically change slide after the specified interval
    const timer = setInterval(() => {
      showSlide((currentIndex + 1) % data.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [currentIndex, data.length]);

  return (
    <>
      <div
        style={{ height: "200px" }}
        className="custom-carousel"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className="custom-carousel-slider" ref={handleCarouselRef}>
          {data.map((item, index) => (
            <div
              key={index}
              style={{ height: "200px" }}
              className={`slide ${index === currentIndex ? "active" : ""}`}
            >
              <ReactPlayer
                controls={true}
                width="100%"
                allowFullScreen={true}
                height={200}
                url={item}
              />
            </div>
          ))}
        </div>
      </div>
      {data.length !== 1 ? (
        <div className="custom-carousel-indicators mt-2">
          {data.map((_, index) => (
            <span
              onClick={() => {
                setCurrentIndex(index);
              }}
              key={index}
              className={`${index === currentIndex ? "active" : ""}`}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default VideoSlider;
