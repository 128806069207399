import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactSpeedometer from "react-d3-speedometer";
import { asyncWrap } from "../utils/utils";
import axios from "axios";

function Switches() {

  const [coilCoins, setCoilCoins] = useState("0");

  useEffect(() => {
    getSwitchPoints();
  }, []);

  const getSwitchPoints = async () => {
    const [error, result] = await asyncWrap(axios.get("/switch_points"));
    if (!result) {
      console.log(error);
      return;
    }
    // console.log(result.data.data)
    setCoilCoins(result.data?.data);
  };

  return (
    <Modal fullscreen={true} show={true}>
      <Modal.Body>
        <div className="d-flex flex-column p-2">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="fs-1">Points earned from Switches</div>
            <div className="d-flex flex-row">
              <div
                style={{
                  background: "#efefef",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                }}
              ></div>
            </div>
          </div>

          <div
            className="d-flex justify-content-center mt-4"
            style={{ height: "170px" }}
          >
            <ReactSpeedometer
              maxValue={500}
              value={coilCoins[0].ShortTermPoint > 500 ? 500 : coilCoins[0].ShortTermPoint}
              segments={5}
              needleTransitionDuration={3000}
            />
          </div>
          <div style={{marginTop:"20px", fontSize:"20px"}}>Short Term Points : {coilCoins[0].ShortTermPoint}</div>
          <div style={{fontSize:"20px"}}>Long Term Points : {coilCoins[0].LongTermPoints}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Switches;
