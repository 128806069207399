import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiDownload } from "react-icons/hi";
import axios from "axios";
import moment from "moment";
import { asyncWrap } from "../../utils/utils";

const SummerBonanza = () => {
  const [SummerBaseTarget, setSummerBaseTarget] = useState("");
  const [SummerGrowthTarget, setSummerGrowthTarget] = useState("");
  const [SummerBaseValue, setSummerBaseValue] = useState("");
  const [SummerTargetValue, setSummerTargetValue] = useState("");
  const [EligibilitySlab, setEligibilitySlab] = useState("");
  const [
    CoilScannedApril1st2024_June30th2024,
    setCoilScannedApril1st2024_June30th2024,
  ] = useState("");
  const [summerBonanza, setSummerBonanza] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (!result) {
      console.log(error);
      return;
    }
    // console.log(result.data.data.PointEarnFromsScan3rdApril2023TillDate)
    if (result) {
      setSummerBaseTarget(result.data?.data?.SummerBaseTarget || "");
      setSummerGrowthTarget(result.data?.data?.SummerGrowthTarget || "");
      setSummerBaseValue(result.data?.data?.SummerBaseValue || "");
      setSummerTargetValue(result.data?.data?.SummerTargetValue || "");
      setEligibilitySlab(result.data?.data?.EligiblitySlab || "");
      setCoilScannedApril1st2024_June30th2024(
        result.data?.data?.CoilScannedApril1st2024_June30th2024 || ""
      );
      setSummerBonanza(result.data?.data?.SummerBonanza || "");
      setLoading(false);
    }
    // setCurrentPoint(result.data?.data?.totalReedemPoint);
  };

  return (
    <>
      <Modal fullscreen={true} show={true}>
        {loading ? (
          <div className="quiz-loader-main">
            <div id="quiz-loader"></div>
          </div>
        ) : (
          <Modal.Body>
            <div className="d-flex text-center flex-column">
              <div
                style={{
                  border: "1px solid #000",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Summer Bonanza
              </div>
              <div className="d-flex flex-column my-4">
                {/* <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Udaan 4.0  : {"  "}</div>
                <div>&emsp;{udaanpoint}</div>
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Current Points : {"  "}</div>
                <div>&emsp;{currentPoint}</div>
              </div> */}

                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Summer Base Target (Coil) : {"  "}
                  </div>
                  <div>&emsp;{SummerBaseTarget}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Summer Growth Target (Coil) : {"  "}
                  </div>
                  <div>&emsp;{SummerGrowthTarget}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Summer Base Value per coil : {"  "}
                  </div>
                  <div>&emsp;{SummerBaseValue}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Summer Growth Value per coil : {"  "}
                  </div>
                  <div>&emsp;{SummerTargetValue}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Eligible Slab : {"  "}
                  </div>
                  <div>&emsp;{EligibilitySlab}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Coil Scan 1st April to till date : {"  "}
                  </div>
                  <div>&emsp;{CoilScannedApril1st2024_June30th2024}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Amount Earned : {"  "}
                  </div>
                  <div>&emsp;{summerBonanza}</div>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default SummerBonanza;
