import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiDownload } from "react-icons/hi";
import axios from "axios";
import { asyncWrap } from "../../utils/utils";

const FestivalBananza = () => {
  const [coilCoins, setCoilCoins] = useState("0");

  useEffect(() => {
    getTotalCoils();
  }, []);

  const getTotalCoils = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (!result) {
      console.log(error);
      return;
    }
    setCoilCoins(result.data?.data.CoilScanedFrom11thOctober2023TillDate);
  };

  return (
    <>
      <Modal fullscreen={true} show={true}>
        <Modal.Body>
          <div className="d-flex text-center flex-column">
            <div
              style={{
                border: "1px solid #000",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              Timeline - 11th Oct'23 to 31st Dec'23
            </div>
            <table className="custom-table">
              <thead>
                <tr style={{ fontSize: "16px" }}>
                  <th style={{ border: "1px solid #000", textAlign: "start" }}>
                    Level
                  </th>
                  <th style={{ border: "1px solid #000", textAlign: "start" }}>
                    Slab Name- Coil
                  </th>
                  <th style={{ border: "1px solid #000", textAlign: "start" }}>
                    Rs per coil
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "16px" }}>
                <tr>
                  <td>1</td>
                  <td>12 to 50</td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>51 to 100</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>101 to 200</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>201 to 300</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>301 to 400</td>
                  <td>14</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>401 to 500</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>501 to 750</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>751 to 1000</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>1000+</td>
                  <td>20</td>
                </tr>
              </tbody>
            </table>
            {/* <div className="my-2 home-card-top-border">
              <div
                onClick={() => {
                  window.open(
                    "https://drive.google.com/file/d/1JIQTAU-xB12ZN2RW3JF_001nlaqgi5CO/preview",
                    "_self"
                  );
                }}
                className="fs-5 mt-3 p-1 home-page-card"
              >
                Terms and Conditions
                <HiDownload style={{ marginLeft: "10px" }} />
              </div>
            </div> */}
            <h3 className="mt-4">Your total coils scanned : { coilCoins}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FestivalBananza;
