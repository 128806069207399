import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiDownload } from "react-icons/hi";
import axios from "axios";
import { Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { asyncWrap } from "../../utils/utils";
import Swal from "sweetalert2";
import { useAuth } from "../../context/auth-context";
import SuccessModal from "../Redeem/SuccessModal";
import FailedModal from "../Redeem/FailedModal";

const MonsoonBananza = () => {
  const [userDetails, setUserDetails] = useState();
  const [panCardNumber, setPanCardNumber] = useState("");
  const [points, setPoints] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (!result) {
      console.log(error);
      Swal.fire("something went wrong!");
      return;
    } else {
      setUserDetails(result?.data?.data);
      setPanCardNumber(result?.data?.data?.PancardNumber);
      setPoints(result?.data?.data?.InstagramId);
    }
  };

  const handlePancardVerify = async () => {
    let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
    if (!pancardpattern.test(panCardNumber)) {
      Swal.fire("Please enter valid pan number");
      return;
    }

    const [panError, panResult] = await asyncWrap(
      axios.post("/verifyKyc/verify-pan", {
        panNumber: panCardNumber,
      })
    );

    if (!panResult) {
      Swal.fire(panError.response.data.data.message);
      return;
    }
    if (!panResult.data.data.valid) {
      Swal.fire(panError.response.data.data.message);
      return;
    } else {
      const [error, result] = await asyncWrap(
        axios.post(
          "/editProfile",
          userDetails?.PancardNumber !== panCardNumber
            ? {
                userType: user?.profile?.roleName || user.roleName,
                kycTypeId: "Pan Card",
                userId: user?.profile?.userId || user.userId,
                panNumber: panCardNumber,
                kycNumber: panCardNumber,
                ispanverify: 1,
              }
            : {
                userType: user?.profile?.roleName || user.roleName,
                userId: user?.profile?.userId || user.userId,
                ispanverify: 1,
              }
        )
      );
      if (!result) {
        Swal.fire(error.response.data.data.message);
      } else if (result.data?.success) {
        Swal.fire(result.data?.message).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire(result?.data?.message);
      }
    }
  };

  const handleRedeemPoints = async () => {
    //eslint-disable-next-line
    const isvalidAccount = new RegExp("[0-9]{9,18}");

    //eslint-disable-next-line
    const isvalidIfsc = new RegExp("^[A-Z]{4}[0][A-Z0-9]{6}$");
    const isName = new RegExp(/^[a-zA-Z0-9\s]+$/);

    if (!isvalidAccount.test(userDetails?.beneficiaryDetail?.accountNumber)) {
      Swal.fire("Please Edit valid Account Number!");
      return;
    }
    if (!isName.test(userDetails?.beneficiaryDetail?.name)) {
      Swal.fire("Please Edit valid Account Holder Name!");
      return;
    }
    if (!isName.test(userDetails?.beneficiaryDetail?.bankName)) {
      Swal.fire("Please Edit valid Bank Name!");
      return;
    }
    if (!isvalidIfsc.test(userDetails?.beneficiaryDetail?.ifscNumber)) {
      Swal.fire("Please Edit valid Ifsc Code!");
      return;
    }

    // if (!userDetails?.IsPanVerified) {
    //   Swal.fire("Please Verified PAN Card!");
    //   return;
    // }

    Swal.fire({
      title: "",
      html:
        `<div>Monsoon Points : ${points}</div>` +
        `<div>Account Number : ${userDetails?.beneficiaryDetail?.accountNumber}</div>` +
        `<div>IFSC Code : ${userDetails?.beneficiaryDetail?.ifscNumber}</div>` +
        `<div>Account Holder Name : ${userDetails?.beneficiaryDetail?.name}</div>` +
        `<div>Bank Name : ${userDetails?.beneficiaryDetail?.bankName}</div>`,
      showCloseButton: true,
      showCancelButton: true,
    }).then(async result => {
      if (result.isConfirmed) {
        const [error, result] = await asyncWrap(
          axios.post("/addRedeem", {
            points: points,
            type: "Bank",
          })
        );

        if (!result) {
          setShowFailedModal(true);
          // Swal.fire(error.response.data.message);
        }
        console.log(result.data.data);
        if (result.data.code === 1) {
          const [EditError, EditResult] = await asyncWrap(
            axios.post("/editProfile", {
              userType: user?.profile?.roleName || user.roleName,
              userId: user?.profile?.userId || user.userId,
              instagramId: "0",
            })
          );
          if (!EditResult) {
            Swal.fire(EditError.response.data.data.message);
          } else if (EditResult.data?.success) {
            Swal.fire(EditResult.data?.message).then(() => {
              window.location.reload();
            });
          } else {
            Swal.fire(EditResult?.data?.message);
            setShowSuccessModal(true);
          }
        } else if (result.data.code === 3) {
          Swal.fire(
            "Your previous payment request is processing, please try again when its completed. Thank you~"
          );
        } else {
          Swal.fire(result.data?.message);
          // Swal.fire(result.data.message);
        }
      }
    });
  };

  return (
    <>
      <SuccessModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        point={points}
      />
      <FailedModal
        show={showFailedModal}
        setShow={setShowFailedModal}
        point={points}
      />
      <Modal fullscreen={true} show={true}>
        <Modal.Body>
          <div className="d-flex text-start flex-column mt-2">
            <div className="d-flex flex-column">
              <div style={{ fontSize: "22px" }}>
                Total Monsoon Points : {userDetails?.InstagramId || 0}
              </div>
            </div>
            <div className="d-flex flex-column mt-4">
              <div style={{ fontSize: "22px", fontWeight: "bold" }}>
                Bank Details
              </div>
              <div style={{ fontSize: "16px" }}>
                Bank Account No. :{" "}
                {userDetails?.beneficiaryDetail.accountNumber}
              </div>
              <div style={{ fontSize: "16px" }}>
                Bank Account Holder Name: {userDetails?.beneficiaryDetail.name}
              </div>
              <div style={{ fontSize: "16px" }}>
                Bank IFSC No. : {userDetails?.beneficiaryDetail.ifscNumber}
              </div>
              <div style={{ fontSize: "16px" }}>
                Bank Name : {userDetails?.beneficiaryDetail.bankName}
              </div>
            </div>
            <div className="d-flex flex-column mt-4">
              <div style={{ fontSize: "22px", fontWeight: "bold" }}>
                PAN Details:
              </div>
              {userDetails?.IsPanVerified ? (
                <div
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  PAN Verified :{" "}
                </div>
              ) : (
                <div
                  style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
                >
                  PAN not Verified{" "}
                </div>
              )}
              <Form className="w-100">
                <FormGroup floating className="p-0">
                  <Input
                    readOnly={userDetails?.IsPanVerified}
                    style={{ border: "1px solid #B0B0B0", width: "100%" }}
                    required
                    onChange={e => setPanCardNumber(e.target.value)}
                    value={panCardNumber}
                    placeholder="Enter Pan Card Number"
                    type="text"
                  />
                  <Label>Pan Card Number</Label>
                </FormGroup>
              </Form>
              <Button
                disabled={userDetails?.IsPanVerified}
                style={{
                  width: "max-content",
                  background: "#3A69DD",
                  color: "white",
                  border: "none",
                  padding: "0.6rem 2rem",
                  marginTop: "0.5rem",
                  fontWeight: "600",
                  borderRadius: "20px",
                }}
                onClick={handlePancardVerify}
              >
                Verify PAN
              </Button>
            </div>
          </div>
          <div>
            <Button
              disabled={points <= 0}
              style={{
                width: "max-content",
                background: "#3A69DD",
                color: "white",
                border: "none",
                padding: "0.6rem 2rem",
                marginTop: "0.5rem",
                fontWeight: "600",
                borderRadius: "20px",
              }}
              onClick={handleRedeemPoints}
            >
              Redeem Points
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MonsoonBananza;
