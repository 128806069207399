import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, FormGroup, Input, Label } from "reactstrap";

const TransferPointsModal = ({
  show,
  setShow,
  mobile,
  setMobile,
  point,
  setPoint,
  addRedeemClick,
}) => {
  // const history = useHistory();
  const { t } = useTranslation();

  return (
    <Modal fullscreen={true} show={show}>
      {/* <Modal.Header> */}
      {/* <SecoundaryHeader
          text={t("transfer_point")}
          sideHide="false"
          onBack={() => {
            history.push("/redeem");
            setShow(false);
          }}
        /> */}
      {/* </Modal.Header> */}
      <Modal.Body className="mt-4">
        <Form className="w-100">
          <FormGroup floating className="p-0">
            <Input
              style={{ border: "1px solid #B0B0B0", width: "100%" }}
              required
              id="transfer_number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter Your Transfer Number"
              type="tel"
            />
            <Label htmlFor="transfer_number">{t("transfer_number")}</Label>
          </FormGroup>
          <FormGroup floating className="p-0">
            <Input
              style={{ border: "1px solid #B0B0B0", width: "100%" }}
              required
              onChange={(e) => setPoint(e.target.value)}
              value={point}
              placeholder="Point"
              id="point"
              type="number"
            />
            <Label htmlFor="point">{t("points")}</Label>
          </FormGroup>
          <div className="d-flex flex-column justify-content-center">
            <button
              onClick={(e) => {
                e.preventDefault();
                addRedeemClick();
              }}
              style={{ background: "#3A69DD", color: "white" }}
              className="fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 "
            >
              {t("redeem")}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default TransferPointsModal;
