import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiDownload } from "react-icons/hi";
import axios from "axios";
import moment from "moment";
import { asyncWrap } from "../../utils/utils";
import { Select } from "antd";
import SuccessModal from "../Redeem/SuccessModal";
import FailedModal from "../Redeem/FailedModal";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { t } from "i18next";

const PointsFormDiwali = () => {
  //   const [udaanpoint, setUdaanPoints] = useState("0");
  //   const [otherPoints, setOtherPoints] = useState("0");
  const [diwaliPoints, setDiwaliPoints] = useState("0");
  const [bankDetails, setBankDetails] = useState();
  const [selectPoint, setSelectPoint] = useState();
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [points_value, setPointsValue] = useState();
  const [totalRedeemPoints, setTotalRedeemPoint] = useState();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState("");
  const [name, setName] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifsc, setIfsc] = useState("");

  const history = useHistory();

  //eslint-disable-next-line
  const isvalidAccount = new RegExp("[0-9]{9,18}");

  //eslint-disable-next-line
  const isvalidIfsc = new RegExp("^[A-Z]{4}[0][A-Z0-9]{6}$");
  const isvalidPaytm = new RegExp(/^[6-9]\d{9}$/);
  const isvalidUpi = new RegExp("[a-zA-Z0-9.-_]{2,256}@[a-zA-Z]{2,64}");
  const isName = new RegExp(/^[a-zA-Z0-9\s]+$/);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (!result) {
      console.log(error);
      return;
    }
    // console.log(result.data.data.PointEarnFromsScan3rdApril2023TillDate)
    if (result) {
      //   setUdaanPoints(result.data?.data?.Udaan_4_0_Eligiblity || "0");
      //   setOtherPoints(result.data?.data?.Other_Points_to_Redeem || "0");
      setDiwaliPoints(result.data?.data?.Points_From_Diwali_Scheme || "0");
      setBankDetails(result.data?.data?.beneficiaryDetail);
      setAccount(result.data?.data?.beneficiaryDetail?.accountNumber);
      setIfsc(result.data?.data?.beneficiaryDetail?.ifscNumber);
      setName(result.data?.data?.beneficiaryDetail?.name);
      setBankName(result.data?.data?.beneficiaryDetail?.bankName);
      GetTotalPoints();
    }
    // setCurrentPoint(result.data?.data?.totalReedemPoint);
  };

  const GetTotalPoints = async () => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      return;
    }
    setTotalRedeemPoint(result.data.data.totalPoints[0].point);
    setLoading(false);
    // setTotalNonRedeemPoint(result.data.data.totalPoints[1].point);
  };

  const isValidPoint = point => {
    if (+point > 0 && point <= totalRedeemPoints && !isNaN(+point)) {
      return false;
    } else {
      setPointsValue(point);
      return true;
    }
  };

  const EditDetails = async () => {
    if (!isvalidAccount.test(account)) {
      Swal.fire("Please Enter valid Account Number!");
      return;
    }
    if (!isName.test(name)) {
      Swal.fire("Please Enter valid Account Holder Name!");
      return;
    }
    if (!isName.test(bankName)) {
      Swal.fire("Please Enter valid Bank Name!");
      return;
    }
    if (!isvalidIfsc.test(ifsc)) {
      Swal.fire("Please Enter valid Ifsc Code!");
      return;
    }
    const data = {
      beneficiaryDetail: {
        name: name,
        accountNumber: account,
        ifscNumber: ifsc,
        bankName: bankName,
      },
    };
    const [error, result] = await asyncWrap(axios.post("/editProfile", data));

    if (!result) {
      Swal.fire(error.response.data.message);
      return;
    }
    setLoading(false);
    Swal.fire(result.data.message).then(() => {
      history.push("/home");
    });
    // setShow(false);
  };

  const AddRedeem = async () => {
    const pointsvalue = parseInt(diwaliPoints);
    if (!isValidPoint(pointsvalue)) {
      //eslint-disable-next-line
      const [error, result] = await asyncWrap(
        axios.post("/addRedeem", {
          points: pointsvalue,
          type: "Bank",
        })
      );
      if (!result) {
        setShowFailedModal(true);
        // Swal.fire(error.response.data.message);
      }
      console.log(result.data.data);
      if (result.data.code === 1) {
        setShowSuccessModal(true);
      } else if (result.data.code === 3) {
        Swal.fire(
          "Your previous payment request is processing, please try again when its completed. Thank you~"
        );
      } else {
        Swal.fire(result.data?.message);
        // Swal.fire(result.data.message);
      }
    } else {
      Swal.fire("Please enter valid points to redeem!");
    }
  };

  return (
    <>
      <SuccessModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        point={points_value}
      />
      <FailedModal
        show={showFailedModal}
        setShow={setShowFailedModal}
        point={points_value}
      />
      <Modal fullscreen={true} show={true}>
        {loading ? (
          <div className="quiz-loader-main">
            <div id="quiz-loader"></div>
          </div>
        ) : (
          <Modal.Body>
            <div className="d-flex text-center flex-column">
              <div
                style={{
                  border: "1px solid #000",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Points Form Diwali Scheme
              </div>
              <div className="d-flex flex-column my-4">
                {/* <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Udaan 4.0  : {"  "}</div>
                <div>&emsp;{udaanpoint}</div>
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Current Points : {"  "}</div>
                <div>&emsp;{currentPoint}</div>
              </div> */}
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Points : {"  "}
                  </div>
                  <div>&emsp;{diwaliPoints}</div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #000",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Bank Details
              </div>
              {/* <div className="d-flex flex-column my-4">
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Account Holder Name : {"  "}
                  </div>
                  <div>&emsp;{bankDetails?.name}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>
                    Account Number : {"  "}
                  </div>
                  <div>&emsp;{bankDetails?.accountNumber}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>Bank Name : {"  "}</div>
                  <div>&emsp;{bankDetails?.bankName}</div>
                </div>
                <div className="d-flex flex-row">
                  <div style={{ fontWeight: "bold" }}>IFSC Number : {"  "}</div>
                  <div>&emsp;{bankDetails?.ifscNumber}</div>
                </div>
              </div> */}
              {/* {(udaanpoint !== "0" || otherPoints !== "0") && (
                <>
                  <div className="text-start">select redeem point value</div>
                  <select
                    placeholder="Please Select Redeem Points"
                    style={{
                      padding: "8px 10px",
                      border: "1px solid black",
                      borderRadius: "5px",
                      background: "white",
                    }}
                    value={selectPoint}
                    onChange={e => {
                      setSelectPoint(e.target.value);
                    }}
                  >
                    {udaanpoint !== "0" && (
                      <option value="udaanpoint">Udaan 4.0 Eligibility</option>
                    )}
                    {otherPoints !== "0" && (
                      <option value="otherPoints">
                        Other Points to Redeem
                      </option>
                    )}
                  </select>
                </>
              )} */}

              <Form className="w-100">
                <FormGroup floating className="p-0 mt-2">
                  <Input
                    style={{ border: "1px solid #B0B0B0", width: "100%" }}
                    required
                    id="account_number"
                    value={account}
                    // disabled={!disableEditDetail}
                    onChange={e => setAccount(e.target.value)}
                    placeholder="Enter Your Account Number"
                    type="number"
                  />
                  <Label htmlFor="account_number">{t("ac_number")}</Label>
                </FormGroup>
                <FormGroup floating className="p-0">
                  <Input
                    style={{ border: "1px solid #B0B0B0", width: "100%" }}
                    required
                    id="ifsc_number"
                    value={ifsc}
                    // disabled={!disableEditDetail}
                    onChange={e => setIfsc(e.target.value)}
                    placeholder="Enter Your IFSC Number"
                    type="text"
                  />
                  <Label htmlFor="ifsc_number">{t("ifsc_code")}</Label>
                </FormGroup>
                <FormGroup floating className="p-0">
                  <Input
                    style={{ border: "1px solid #B0B0B0", width: "100%" }}
                    required
                    id="account_name"
                    value={name}
                    // disabled={!disableEditDetail}
                    onChange={e => setName(e.target.value)}
                    placeholder="Enter Your Account Holder Name"
                    type="text"
                  />
                  <Label htmlFor="account_name">{t("name")}</Label>
                </FormGroup>
                <FormGroup floating className="p-0">
                  <Input
                    style={{ border: "1px solid #B0B0B0", width: "100%" }}
                    required
                    id="bank_name"
                    value={bankName}
                    // disabled={!disableEditDetail}
                    onChange={e => setBankName(e.target.value)}
                    placeholder="Enter your bank name"
                    type="text"
                  />
                  <Label htmlFor="bank_name">{t("bank_name")}</Label>
                </FormGroup>
              </Form>

              <button
                onClick={e => {
                  e.preventDefault();
                  EditDetails();
                }}
                style={{ background: "#3A69DD", color: "white" }}
                className="fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 mt-2"
              >
                Edit Bank Details
              </button>

              {diwaliPoints !== "0" && (
                <button
                  onClick={e => {
                    e.preventDefault();
                    AddRedeem();
                  }}
                  style={{ background: "#3A69DD", color: "white" }}
                  className="fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 mt-2"
                >
                  Redeem
                </button>
              )}
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default PointsFormDiwali;
