import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { HiDownload } from 'react-icons/hi';
import axios from 'axios';
import { asyncWrap } from '../../utils/utils';

const FestivalBananza24 = () => {
  const [coilCoins, setCoilCoins] = useState(0);

  useEffect(() => {
    getTotalCoils();
  }, []);

  const getTotalCoils = async () => {
    const [error, result] = await asyncWrap(axios.get('/userDetails'));
    if (!result) {
      console.log(error);
      return;
    }
    setCoilCoins(parseInt(result.data?.data.CoilCountScan1Octto31dec));
  };

  return (
    <>
      <Modal fullscreen={true} show={true}>
        <Modal.Body>
          <div className='d-flex text-center flex-column'>
            {/* <div
              style={{
                border: "1px solid #000",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              Festival Bonanza 2024
            </div> */}
            <table className='custom-table'>
              <thead>
                <tr style={{ fontSize: '16px' }}>
                  <th style={{ border: '1px solid #000', textAlign: 'start' }}>
                    Slab
                  </th>
                  <th style={{ border: '1px solid #000', textAlign: 'start' }}>
                    Slab Coil Count Minimum
                  </th>
                  <th style={{ border: '1px solid #000', textAlign: 'center' }}>
                    Gift
                  </th>
                  <th style={{ border: '1px solid #000', textAlign: 'center' }}>
                    How many coils scanned (1st October to 31st December) 
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: '16px', textAlign:"center" }}>
                <tr>
                  <td>L1</td>
                  <td style={{textAlign:"center"}}>31</td>
                  <td>1200 points to be added in Udaan 5.0</td>
                  <td>{ (coilCoins >= 31 && coilCoins < 61) ? coilCoins : "" }</td>
                </tr>

                <tr>
                  <td>L2</td>
                  <td style={{textAlign:"center"}}>61</td>
                  <td>2800 points to be added in Udaan 5.0</td>
                  <td>{ (coilCoins >= 61 && coilCoins < 101) ? coilCoins : "" }</td>
                </tr>
                <tr>
                  <td>L3</td>
                  <td style={{textAlign:"center"}}>101</td>
                  <td>4800 points to be added in Udaan 5.0</td>
                  <td>{ (coilCoins >= 101 && coilCoins < 201) ? coilCoins : "" }</td>
                </tr>
                <tr>
                  <td>L4</td>
                  <td style={{textAlign:"center"}}>201</td>
                  <td>10400 points to be added in Udaan 5.0</td>
                  <td>{ (coilCoins >= 201 && coilCoins < 301) ? coilCoins : "" }</td>
                </tr>
                <tr>
                  <td>L5</td>
                  <td style={{textAlign:"center"}}>301</td>
                  <td>16800 points to be added in Udaan 5.0</td>
                  <td>{ (coilCoins >= 301 && coilCoins < 501) ? coilCoins : "" }</td>
                </tr>
                <tr>
                  <td>L6</td>
                  <td style={{textAlign:"center"}}>501</td>
                  <td>30000 points to be added in Udaan 5.0</td>
                  <td>{ (coilCoins >= 501 && coilCoins < 776) ? coilCoins : "" }</td>
                </tr>
                <tr>
                  <td>L7</td>
                  <td style={{textAlign:"center"}}>776</td>
                  <td>Silver or Gold Voucher - Rs. 12400/-</td>
                  <td>{ (coilCoins >= 776 && coilCoins < 1251) ? coilCoins : "" }</td>
                </tr>
                <tr>
                  <td>L8</td>
                  <td style={{textAlign:"center"}}>1251</td>
                  <td>Silver or Gold Voucher - Rs. 20200/-</td>
                  <td>{ (coilCoins >= 1251 && coilCoins < 2001) ? coilCoins : "" }</td>
                </tr>
                <tr>
                  <td>L9</td>
                  <td style={{textAlign:"center"}}>2001</td>
                  <td>Silver or Gold Voucher - Rs. 34000/-</td>
                  <td>{ (coilCoins >= 2001 && coilCoins < 3001) ? coilCoins : "" }</td>
                </tr>
                <tr>
                  <td>L10</td>
                  <td style={{textAlign:"center"}}>3001</td>
                  <td>Silver or Gold Voucher - Rs. 52500/-</td>
                  <td>{ (coilCoins >= 3001 && coilCoins < 5001) ? coilCoins : "" }</td>
                </tr>
                <tr>
                  <td>L11</td>
                  <td style={{textAlign:"center"}}>5001</td>
                  <td>E Bike(Non-Negotiable)</td>
                  <td>{ (coilCoins >= 5001) ? coilCoins : "" }</td>
                </tr>
              </tbody>
            </table>
            {/* <div className="my-2 home-card-top-border">
              <div
                onClick={() => {
                  window.open(
                    "https://drive.google.com/file/d/1JIQTAU-xB12ZN2RW3JF_001nlaqgi5CO/preview",
                    "_self"
                  );
                }}
                className="fs-5 mt-3 p-1 home-page-card"
              >
                Terms and Conditions
                <HiDownload style={{ marginLeft: "10px" }} />
              </div>
            </div> */}
            {/* <h3 className='mt-4'>Your total coils scanned : {coilCoins}</h3> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FestivalBananza24;
