import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { HiDownload } from 'react-icons/hi';
import axios from 'axios';
import { asyncWrap } from '../../utils/utils';

const DostiBonce = () => {
  const [coilCoins, setCoilCoins] = useState(0);
  const [slabPoints, setSlabPoints] = useState([]);
  const [totalAdditionalPoints, setTotalAdditionalPoints] = useState(0);

  useEffect(() => {
    getTotalCoils();
  }, []);

  const getTotalCoils = async () => {
    const [error, result] = await asyncWrap(axios.get('/userDetails'));
    if (!result) {
      console.log(error);
      return;
    }
    const coilCoins = parseInt(
      result.data?.data?.CoilScannedJuly1st2024_March31st2025DostiBonus
    );
    // const coilCoins = 73
    setCoilCoins(coilCoins);
    const { slabPoints, totalAdditionalPoints } = calculateAdditionalPoints(coilCoins);
    setSlabPoints(slabPoints);
    setTotalAdditionalPoints(totalAdditionalPoints);
  };

  const calculateAdditionalPoints = coil_Coins => {
    const slabs = [
      { max: 10, points: 0 },
      { max: 25, points: 8 },
      // { max: , points: 12 },
      { max: 50, points: 14 },
      { max: 75, points: 20 },
      { max: 100, points: 24 },
      { max: 200, points: 32 },
      { max: 500, points: 40 },
      { max: 1000, points: 48 },
      { max: Infinity, points: 56 },
    ];

    let remainingCoins = coil_Coins;
    let totalAdditionalPoints = 0;

    const slabPoints = slabs.map((slab, index) => {
      const { max, points } = slab;
      const previousMax = index === 0 ? 0 : slabs[index - 1].max;
      const slabCoils = Math.min(max - previousMax, remainingCoins);

      remainingCoins -= slabCoils;
      const additionalPoints = slabCoils * points;

      totalAdditionalPoints += additionalPoints;

      return {
        slab: `${previousMax + 1} to ${max === Infinity ? 'above' : max} Coil`,
        coilCount: max - previousMax,
        additionalPointsEach: points,
        yourCoilScanned:
          coil_Coins >= previousMax + 1 && coil_Coins <= max ? coil_Coins : '',
        slabWiseAdditionalPoints: additionalPoints,
      };
    });

    return { slabPoints, totalAdditionalPoints };
  };

  // const { slabPoints, totalAdditionalPoints } = calculateAdditionalPoints();

  return (
    <>
      <Modal fullscreen={true} show={true}>
        <Modal.Body>
          <div className='d-flex text-center flex-column'>
            <table className='custom-table'>
              <thead>
                <tr>
                  <th>Slab</th>
                  <th>Coil Count</th>
                  <th>Additional Points Each</th>
                  <th>Your Coil Scanned</th>
                  <th>Slab Wise Additional Point</th>
                </tr>
              </thead>
              <tbody>
                {slabPoints.map((slab, index) => (
                  <tr key={index}>
                    <td>{slab.slab}</td>
                    <td>{slab.coilCount}</td>
                    <td>{slab.additionalPointsEach}</td>
                    <td>{slab.yourCoilScanned}</td>
                    <td>{slab.slabWiseAdditionalPoints ? slab.slabWiseAdditionalPoints : (index == 0 && coilCoins > 0) ? "0" : ""}</td>
                  </tr>
                ))}
              </tbody>
              {/* <tbody>
                <tr>
                  <td>0 to 10th</td>
                  <td>10</td>
                  <td>0</td>
                  <td>{ (coilCoins >= 0 && coilCoins <= 10) ? coilCoins : "" }</td>
                  <td>{ (coilCoins >= 0 && coilCoins <= 10) ? (coilCoins - 0) * 0 : (coilCoins >= 10) ? 10 * 0 : "" }</td>
                </tr>
                <tr>
                  <td>11th To 20th Coil</td>
                  <td>10</td>
                  <td>8</td>
                  <td>{ (coilCoins >= 11 && coilCoins <= 20) ? coilCoins : "" }</td>
                  <td>{ (coilCoins >= 11 && coilCoins <= 20) ? (coilCoins - 10) * 8 : (coilCoins >= 10) ? 10 * 8 : "" }</td>
                </tr>
                <tr>
                  <td>21st To 35th Coil</td>
                  <td>15</td>
                  <td>12</td>
                  <td>{ (coilCoins >= 21 && coilCoins <= 35) ? coilCoins : "" }</td>
                  <td>{ (coilCoins >= 21 && coilCoins <= 35) ? (coilCoins - 21) * 8 : (coilCoins >= 10) ? 15 * 12 : "" }</td>
                </tr>
                <tr>
                  <td>36th To 50th Coil</td>
                  <td>15</td>
                  <td>16</td>
                  <td></td>
                  <td>240</td>
                </tr>
                <tr>
                  <td>51st To 75th Coil</td>
                  <td>25</td>
                  <td>20</td>
                  <td>72</td>
                  <td>440</td>
                </tr>
                <tr>
                  <td>76th To 100th Coil</td>
                  <td>25</td>
                  <td>24</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>101st To 200th Coil</td>
                  <td>100</td>
                  <td>32</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>201st To 500th Coil</td>
                  <td>300</td>
                  <td>40</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>501st To 1000th Coil</td>
                  <td>500</td>
                  <td>48</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>1001st & above</td>
                  <td>1+</td>
                  <td>56x...</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody> */}
              <tfoot>
                <tr>
                  <td colSpan='4'>Total Additional Point</td>
                  <td>{totalAdditionalPoints}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DostiBonce;
