import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiDownload } from "react-icons/hi";
import axios from "axios";
import moment from "moment";
import { asyncWrap } from "../../utils/utils";

const ManagerDetails = () => {
  const [psoData, setPSOData] = useState("");
  const [SEEmpId, setSEEmpId] = useState("");
  const [SEName, setSEName] = useState("");
  const [asmId, setAsmId] = useState("");
  const [asmName, setAsmName] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (!result) {
      console.log(error);
      return;
    }

    setPSOData(result.data?.data?.PSO);
    setSEEmpId(result.data?.data?.SEEmpID);
    setSEName(result.data?.data?.SEName);
    setAsmId(result.data?.data?.ASMEmpID);
    setAsmName(result.data?.data?.ASMName);
  };

  return (
    <>
      <Modal fullscreen={true} show={true}>
        <Modal.Body>
          <div className="d-flex text-center flex-column">
            <div
              style={{
                border: "1px solid #000",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Manager Details
            </div>
            <div className="d-flex flex-column my-4">
              <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>PSO : {"  "}</div>
                <div>&emsp;{psoData}</div>
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Your SE Id : {"  "}</div>
                <div>&emsp;{SEEmpId}</div>
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Your SE Name : {"  "}</div>
                <div>&emsp;{SEName}</div>
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Your ASM ID : {"  "}</div>
                <div>&emsp;{asmId}</div>
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: "bold" }}>Your ASM Name : {"  "}</div>
                <div>&emsp;{asmName}</div>
              </div>
            </div>

            <p>Please call them to approve your KYC.</p>
            <p>
              Please connect with our call centre 1800 103 6633 for any other
              queries.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManagerDetails;
