import axios from 'axios';
import { useEffect, useState } from 'react';
import { Carousel, Image, Modal } from 'react-bootstrap';
import ScratchCard from 'react-scratchcard-v3';
import { asyncWrap } from '../../utils/utils';
import close from '/assets/images/popclose.png';
import scratchImage from '/assets/banner.png';
import { useAuth } from '../../context/auth-context';
import Swal from 'sweetalert2';

const LuckyWinnerBanner = ({ show, setShow, setShowLuckyDraw }) => {
  return (
    <>
      <Modal
        fullscreen={false}
        backdrop='static'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={show}
        onHide={() => {
          setShowLuckyDraw(true);
          setShow(false)
        }}
      >
        <Modal.Body className='m-0 p-0 text-left'>
          <div
            style={{
              position: 'absolute',
              top: '-30px', // Set desired top margin
              left: '50%',
              transform: 'translateX(-50%)', // Center horizontally
              fontSize: '20px',
              fontWeight: '600',
              color: 'white',
              textAlign: 'center',
            }}
          >
            Lucky Draw
          </div>
          <Image
            src={close}
            alt=''
            className='modal-close'
            onClick={() => {
              setShowLuckyDraw(true);
              setShow(false);
              // history.push("/home");
            }}
          />
          <div width={285} height={346}>
            <Image
              style={{ width: '285px', height: 'auto',borderRadius:"6px" }}
              src={scratchImage}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default LuckyWinnerBanner;
